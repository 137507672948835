import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
export class PutStore {
  constructor() {
    this.putQueue = {};
  }
  register(paramsUrl, httpCall) {
    let returnObs;
    if (!this.putQueue[paramsUrl]) {
      returnObs = new ReplaySubject(1);
      this.putQueue[paramsUrl] = [{
        obs: returnObs,
        call: httpCall,
        running: false
      }];
    } else if (this.putQueue[paramsUrl].length < 2) {
      returnObs = new ReplaySubject(1);
      this.putQueue[paramsUrl].push({
        obs: returnObs,
        call: httpCall,
        running: false
      });
    } else if (this.putQueue[paramsUrl].length === 2) {
      this.putQueue[paramsUrl][1].call = httpCall;
      returnObs = this.putQueue[paramsUrl][1].obs;
    } else {
      throw new Error('this cant happen');
    }
    this.check(paramsUrl);
    return returnObs;
  }
  check(paramsUrl) {
    if (this.putQueue[paramsUrl] && this.putQueue[paramsUrl][0] && !this.putQueue[paramsUrl][0].running) {
      this.putQueue[paramsUrl][0].running = true;
      this.putQueue[paramsUrl][0].call.pipe(take(1)).subscribe(result => {
        this.putQueue[paramsUrl][0].obs.next(result);
        this.putQueue[paramsUrl][0].obs.complete();
        this.putQueue[paramsUrl].shift();
        this.check(paramsUrl);
      }, error => {
        this.putQueue[paramsUrl][0].obs.error(error);
        this.putQueue[paramsUrl].shift();
        this.check(paramsUrl);
      });
    }
  }
}
