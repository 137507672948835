import { ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { generateUrl } from '../utils/generate-url';
export class EntityStore {
  constructor() {
    this.store = new Map();
  }
  refresh(entity, url, method, paramsUrl) {
    if (method === 'put') {
      console.debug('[Entity store]', 'refresh for put');
      this.refreshUrl(entity, url);
      const parts = url.split('/');
      parts.pop();
      this.refreshUrl(entity, parts.join('/'));
    } else if (method === 'delete') {
      console.debug('[Entity store]', 'refresh for delete');
      const parts = url.split('/');
      parts.pop();
      this.refreshUrl(entity, parts.join('/'));
    } else {
      console.debug('[Entity store]', 'refresh for other');
      this.refreshUrl(entity, url, paramsUrl);
    }
  }
  /**
   * TODO: Needed?
   * @param entity
   * @param data
   */
  set(entity, data) {
    throw new Error('[EntityStore], set, not implemented');
  }
  get(entity, url, call, config, method, params) {
    const paramsUrl = generateUrl(url, params);
    console.debug('[EntityStore] get, entity', entity);
    console.debug('[EntityStore] get, call', call);
    console.debug('[EntityStore] get, config', config);
    console.debug('[EntityStore] get, url', url);
    console.debug('[EntityStore] get, method', method);
    if (!this.store.has(entity)) {
      console.debug('[EntityStore] get, new entity');
      this.store.set(entity, new Map());
    }
    if (!this.store.get(entity).has(url)) {
      console.debug('[EntityStore] get, new url');
      this.store.get(entity).set(url, new Map());
    }
    if (config.forceRefresh || !this.store.get(entity).get(url).has(paramsUrl) || this.store.get(entity).get(url).get(paramsUrl).data.isStopped) {
      console.debug('[EntityStore] get, new store');
      this.store.get(entity).get(url).set(paramsUrl, {
        data: new ReplaySubject(1),
        callback: call,
        identifier: url
      });
      this.refresh(entity, url, method, paramsUrl);
    }
    return this.store.get(entity).get(url).get(paramsUrl).data.pipe(map(data => {
      return JSON.parse(JSON.stringify(data));
    }));
  }
  refreshUrl(entity, url, paramsUrl) {
    console.debug('[EntityStore] refresh, entity', entity);
    console.debug('[EntityStore] refresh, identifier', url);
    if (this.store.has(entity)) {
      let requests = this.store.get(entity).get(url);
      if (requests) {
        if (paramsUrl) {
          console.debug('[EntityStore] refresh, single', paramsUrl);
          const request = this.store.get(entity).get(url).get(paramsUrl);
          if (request) {
            requestCallback(request);
          } else {
            console.error('[EntityStore] refresh, single, does not exist', paramsUrl);
          }
        } else {
          requests.forEach((request, url) => {
            requestCallback(request);
          });
        }
      }
    }
  }
}
function requestCallback(request) {
  if (!request.callback) {
    throw new Error('[EntityStore] Tried to refresh entity without having a callback registered');
  }
  request.callback.pipe(take(1)).subscribe(data => {
    console.debug('[EntityStore] refreshed identifier', request.identifier);
    request.data.next(data);
  }, error => {
    request.data.error(error);
  });
}
