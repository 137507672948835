import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { DekraDesignSystemModule } from '@dekra-ds/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './footer/footer.component';
import { DekraCostToolClientModule, DekraCostToolClientService } from '@dekra-api/cost-tool-client';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    DekraDesignSystemModule,
    DekraCostToolClientModule,
    HttpClientModule,
    TranslateModule.forChild(),
  ],
})
export class CoreModule {
  constructor(private backend: DekraCostToolClientService) {
    this.backend.setBaseUrl(environment.backendUrl);
  }
}
