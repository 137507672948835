var defaultTranslationsEnglish = {
  DEKRA_CHECKBOX: {
    ERROR: {
      REQUIRED: "Please tick this box if you want to proceed."
    }
  },
  DEKRA_DATEPICKER: {
    ERROR: {
      INVALID_DATE: "Please enter a valid date with format {{format}}.",
      MIN_DATE: "The date must be later than or equal to {{min}}.",
      MAX_DATE: "The date must be earlier than or equal to {{max}}.",
      MIN_TIME: "The time must be later than or equal to {{min}}.",
      MAX_TIME: "The time must be earlier than or equal to {{max}}.",
      REQUIRED: "Please fill in this field."
    },
    MONTH: {
      LONG: {
        JANUARY: "January",
        FEBRUARY: "February",
        MARCH: "March",
        APRIL: "April",
        MAY: "May",
        JUNE: "June",
        JULY: "July",
        AUGUST: "August",
        SEPTEMBER: "September",
        OCTOBER: "October",
        NOVEMBER: "November",
        DECEMBER: "December"
      },
      SHORT: {
        JANUARY: "Jan",
        FEBRUARY: "Feb",
        MARCH: "Mar",
        APRIL: "Apr",
        MAY: "May",
        JUNE: "Jun",
        JULY: "Jul",
        AUGUST: "Aug",
        SEPTEMBER: "Sept",
        OCTOBER: "Oct",
        NOVEMBER: "Nov",
        DECEMBER: "Dec"
      }
    },
    WEEKDAY: {
      LONG: {
        SUNDAY: "Sunday",
        MONDAY: "Monday",
        TUESDAY: "Tuesday",
        WEDNESDAY: "Wednesday",
        THURSDAY: "Thursday",
        FRIDAY: "Friday",
        SATURDAY: "Saturday"
      },
      SHORT: {
        SUNDAY: "Su",
        MONDAY: "Mo",
        TUESDAY: "Tu",
        WEDNESDAY: "We",
        THURSDAY: "Th",
        FRIDAY: "Fr",
        SATURDAY: "Sa"
      }
    },
    TODAY: "Today",
    NOW: "Now",
    HOURS: "Hours",
    MINUTES: "Minutes"
  },
  DEKRA_FILE_UPLOAD: {
    CHOOSE_FILE: "Choose file",
    CHOOSE_FILES: "Choose files",
    ERROR: {
      REQUIRED: "Please select a file."
    },
    FILE: "file",
    FILES: "files",
    NO_FILE: "No file chosen",
    NO_FILES: "No files chosen"
  },
  DEKRA_INPUT: {
    ERROR: {
      MIN: "The value must be greater than or equal to {{min}}.",
      MAX: "The value must be less than or equal to {{max}}.",
      MINLENGTH: "Please lengthen the text to {{requiredLength}} characters or more. Currently you are using {{actualLength}} characters.",
      MAXLENGTH: "Please shorten the text to {{requiredLength}} characters or less. Currently you are using {{actualLength}} characters.",
      REQUIRED: "Please fill in this field.",
      EMAIL: "Please enter a valid email address.",
      PATTERN: "Please match the format requested."
    }
  },
  DEKRA_HEADER: {
    ACTIONS: "Actions",
    NAVIGATION: "Navigation",
    SEARCH: "Search"
  },
  DEKRA_PAGING: {
    ITEMS: "Items",
    OF: "of",
    PAGE: "page",
    ROWS: "Items per page"
  },
  DEKRA_RADIO_BUTTON_GROUP: {
    ERROR: {
      REQUIRED: "Please select one of these options."
    }
  },
  DEKRA_SELECT: {
    ERROR: {
      REQUIRED: "Please select an item in the list."
    }
  },
  DEKRA_TABLE: {
    NO_ENTRIES: "No matching entries found",
    ACTIONS: "Actions",
    SELECTION: {
      SELECT_ALL: "Select all",
      DESELECT_ALL: "Deselect all"
    },
    SORTING: {
      RESET: "Reset Sorting"
    },
    CONFIGURATION: {
      TITLE: "Table Configuration",
      COLUMN: "Column",
      VISIBLE: "Visible",
      ORDER: "Order",
      CANCEL: "Cancel",
      RESET: "Reset Config",
      OK: "Ok"
    },
    FILTER: {
      RESET: "Reset Filter",
      TITLE: "Filter",
      ALL: "All",
      CHECKED: "Checked",
      UNCHECKED: "Unchecked",
      EMPTY: "Empty"
    },
    PAGINATOR: {
      ITEMS: "Items",
      OF: "of",
      PAGE: "Page",
      ROWS: "Rows per page"
    },
    SEARCH: "Search"
  },
  DEKRA_TAG_LIST: {
    ERROR: {
      REQUIRED: "At least one entry is required."
    }
  },
  DEKRA_TEXTAREA: {
    ERROR: {
      MINLENGTH: "Please lengthen the text to {{requiredLength}} characters or more. Currently you are using {{actualLength}} characters.",
      MAXLENGTH: "Please shorten the text to {{requiredLength}} characters or less. Currently you are using {{actualLength}} characters.",
      REQUIRED: "Please fill in this field."
    }
  },
  DEKRA_TOGGLE: {
    ERROR: {
      REQUIRED: "Please tick this box if you want to proceed."
    }
  },
  DEKRA_TYPEAHEAD: {
    ERROR: {
      MINLENGTH: "Please lengthen the text to {{requiredLength}} characters or more. Currently you are using {{actualLength}} characters.",
      MAXLENGTH: "Please shorten the text to {{requiredLength}} characters or less. Currently you are using {{actualLength}} characters.",
      REQUIRED: "Please fill in this field."
    },
    NO_MATCH: "No matching entries found"
  }
};
var defaultTranslationsGerman = {
  DEKRA_CHECKBOX: {
    ERROR: {
      REQUIRED: "Klicken Sie dieses Kästchen an, wenn Sie fortfahren möchten."
    }
  },
  DEKRA_DATEPICKER: {
    ERROR: {
      INVALID_DATE: "Ihre Eingabe muss ein gültiges Datum mit dem Format {{format}} sein.",
      MIN_DATE: "Das Datum muss später als oder gleich {{min}} sein.",
      MAX_DATE: "Das Datum muss früher als oder gleich {{max}} sein.",
      MIN_TIME: "Die Zeit muss später als oder gleich {{min}} sein.",
      MAX_TIME: "Die Zeit muss früher als oder gleich {{max}} sein.",
      REQUIRED: "Füllen Sie dieses Feld aus."
    },
    MONTH: {
      LONG: {
        JANUARY: "Januar",
        FEBRUARY: "Februar",
        MARCH: "März",
        APRIL: "April",
        MAY: "Mai",
        JUNE: "Juni",
        JULY: "Juli",
        AUGUST: "August",
        SEPTEMBER: "September",
        OCTOBER: "Oktober",
        NOVEMBER: "November",
        DECEMBER: "Dezember"
      },
      SHORT: {
        JANUARY: "Jan",
        FEBRUARY: "Feb",
        MARCH: "März",
        APRIL: "Apr",
        MAY: "Mai",
        JUNE: "Jun",
        JULY: "Jul",
        AUGUST: "Aug",
        SEPTEMBER: "Sept",
        OCTOBER: "Okt",
        NOVEMBER: "Nov",
        DECEMBER: "Dez"
      }
    },
    WEEKDAY: {
      LONG: {
        SUNDAY: "Sonntag",
        MONDAY: "Montag",
        TUESDAY: "Dienstag",
        WEDNESDAY: "Mittwoch",
        THURSDAY: "Donnerstag",
        FRIDAY: "Freitag",
        SATURDAY: "Samstag"
      },
      SHORT: {
        SUNDAY: "So",
        MONDAY: "Mo",
        TUESDAY: "Di",
        WEDNESDAY: "Mi",
        THURSDAY: "Do",
        FRIDAY: "Fr",
        SATURDAY: "Sa"
      }
    },
    TODAY: "Heute",
    NOW: "Jetzt",
    HOURS: "Stunden",
    MINUTES: "Minuten"
  },
  DEKRA_FILE_UPLOAD: {
    CHOOSE_FILE: "Datei auswählen",
    CHOOSE_FILES: "Dateien auswählen",
    ERROR: {
      REQUIRED: "Wählen Sie eine Datei aus."
    },
    FILE: "Datei",
    FILES: "Dateien",
    NO_FILE: "Keine Datei ausgewählt",
    NO_FILES: "Keine Dateien ausgewählt"
  },
  DEKRA_INPUT: {
    ERROR: {
      MIN: "Der Wert muss größer als oder gleich {{min}} sein.",
      MAX: "Der Wert muss kleiner als oder gleich {{max}} sein.",
      MINLENGTH: "Verlängern Sie diesen Text auf mindestens {{requiredLength}} Zeichen. Derzeit verwenden Sie {{actualLength}} Zeichen.",
      MAXLENGTH: "Verkürzen Sie diesen Text auf maximal {{requiredLength}} Zeichen. Derzeit verwenden Sie {{actualLength}} Zeichen.",
      REQUIRED: "Füllen Sie dieses Feld aus.",
      EMAIL: "Ihre Eingabe muss eine gültige Email sein.",
      PATTERN: "Ihre Eingabe muss mit dem geforderten Format übereinstimmen."
    }
  },
  DEKRA_HEADER: {
    ACTIONS: "Aktionen",
    NAVIGATION: "Navigation",
    SEARCH: "Suche"
  },
  DEKRA_PAGING: {
    ITEMS: "Einträge",
    OF: "von",
    PAGE: "Seite",
    ROWS: "Einträge pro Seite"
  },
  DEKRA_RADIO_BUTTON_GROUP: {
    ERROR: {
      REQUIRED: "Wählen Sie eine dieser Optionen aus."
    }
  },
  DEKRA_SELECT: {
    ERROR: {
      REQUIRED: "Wählen Sie ein Element in der Liste aus."
    }
  },
  DEKRA_TABLE: {
    NO_ENTRIES: "Keine passenden Einträge gefunden",
    ACTIONS: "Aktionen",
    SELECTION: {
      SELECT_ALL: "Alle selektieren",
      DESELECT_ALL: "Alle deselektieren"
    },
    SORTING: {
      RESET: "Sortierung zurücksetzen"
    },
    CONFIGURATION: {
      TITLE: "Tabellen Konfiguration",
      COLUMN: "Spalte",
      VISIBLE: "Sichtbar",
      ORDER: "Reihenfolge",
      CANCEL: "Abbrechen",
      RESET: "Zurücksetzen",
      OK: "Ok"
    },
    FILTER: {
      RESET: "Filter zurücksetzen",
      TITLE: "Filter",
      ALL: "Alle",
      CHECKED: "Markiert",
      UNCHECKED: "Unmarkiert",
      EMPTY: "Leer"
    },
    PAGINATOR: {
      ITEMS: "Elemente",
      OF: "von",
      PAGE: "Seite",
      ROWS: "Zeilen pro Seite"
    },
    SEARCH: "Suche"
  },
  DEKRA_TAG_LIST: {
    ERROR: {
      REQUIRED: "Mindestens ein Eintrag erforderlich."
    }
  },
  DEKRA_TEXTAREA: {
    ERROR: {
      MINLENGTH: "Verlängern Sie diesen Text auf mindestens {{requiredLength}} Zeichen. Derzeit verwenden Sie {{actualLength}} Zeichen.",
      MAXLENGTH: "Verkürzen Sie diesen Text auf maximal {{requiredLength}} Zeichen. Derzeit verwenden Sie {{actualLength}} Zeichen.",
      REQUIRED: "Füllen Sie dieses Feld aus."
    }
  },
  DEKRA_TOGGLE: {
    ERROR: {
      REQUIRED: "Klicken Sie dieses Kästchen an, wenn Sie fortfahren möchten."
    }
  },
  DEKRA_TYPEAHEAD: {
    ERROR: {
      MINLENGTH: "Verlängern Sie diesen Text auf mindestens {{requiredLength}} Zeichen. Derzeit verwenden Sie {{actualLength}} Zeichen.",
      MAXLENGTH: "Verkürzen Sie diesen Text auf maximal {{requiredLength}} Zeichen. Derzeit verwenden Sie {{actualLength}} Zeichen.",
      REQUIRED: "Füllen Sie dieses Feld aus."
    },
    NO_MATCH: "Keine passenden Einträge gefunden"
  }
};
function isObject(e) {
  return e && typeof e === "object" && !Array.isArray(e);
}
function mergeDeep(e, t) {
  var n = Object.assign({}, e);
  if (isObject(e) && isObject(t)) {
    Object.keys(t).forEach(function (r) {
      var i, a;
      if (isObject(t[r])) {
        if (!(r in e)) {
          Object.assign(n, (i = {}, i[r] = t[r], i));
        } else {
          n[r] = mergeDeep(e[r], t[r]);
        }
      } else {
        Object.assign(n, (a = {}, a[r] = t[r], a));
      }
    });
  }
  return n;
}
var PARAMS_MATCHER = /{{\s?([^{}\s]*)\s?}}/g;
function getBrowserLocale() {
  var e = window.navigator.languages ? window.navigator.languages[0] : window.navigator.language;
  if (!e) {
    e = "en";
  } else if (e.indexOf("-") !== -1) {
    e = e.split("-")[0];
  } else if (e.indexOf("_") !== -1) {
    e = e.split("_")[0];
  }
  return e;
}
function interpolateParams(e, t) {
  for (var n = 0, r = Object.keys(e); n < r.length; n++) {
    var i = r[n];
    if (typeof e[i] === "string") {
      e[i] = interpolateParam(e[i], t);
    } else {
      e[i] = interpolateParams(e[i], t);
    }
  }
  return e;
}
function interpolateParam(e, t) {
  return !!e ? e.replace(PARAMS_MATCHER, function (e, n) {
    return t[n] !== undefined && t[n] !== null ? t[n] : e;
  }) : undefined;
}
var LocaleStore = function () {
  function e() {
    this.availableLocales = ["en", "de"];
    this.defaultLocale = getBrowserLocale();
    this.currentLocale = this.defaultLocale;
    this._translations = {
      de: defaultTranslationsGerman,
      en: defaultTranslationsEnglish
    };
    this._dateFormats = {
      de: "dd.MM.YYYY",
      en: "MM/dd/YYYY"
    };
    this._timeFormats = {
      de: "HH:mm",
      en: "HH:mm"
    };
  }
  e.prototype.setTranslations = function (e, t, n) {
    if (n === void 0) {
      n = true;
    }
    if (this.availableLocales.indexOf(e) === -1) {
      this.availableLocales.push(e);
    }
    if (n && !!this._translations[e]) {
      this._translations[e] = mergeDeep(this._translations[e], t);
    } else {
      this._translations[e] = t;
    }
  };
  e.prototype.getTranslations = function (e, t) {
    var n = this._translations[e];
    if (t) {
      return interpolateParams(n, t);
    }
    return n;
  };
  e.prototype.getComponentTranslations = function (e, t) {
    e = e.toUpperCase().replace(/-/g, "_");
    var n = this._translations[this.currentLocale][e];
    if (t) {
      return interpolateParams(n, t);
    }
    return n;
  };
  e.prototype.getTranslation = function (e, t) {
    var n = e.split(".").reduce(function (e, t) {
      return e ? e[t] : undefined;
    }, this._translations[this.currentLocale]);
    if (t) {
      return interpolateParam(n, t);
    }
    return n;
  };
  e.prototype.setDateFormat = function (e, t) {
    this._dateFormats[e] = t;
  };
  e.prototype.getDateFormat = function (e) {
    return this._dateFormats[e];
  };
  e.prototype.getCurrentDateFormat = function () {
    return this._dateFormats[this.currentLocale];
  };
  e.prototype.setTimeFormat = function (e, t) {
    this._timeFormats[e] = t;
  };
  e.prototype.getTimeFormat = function (e) {
    return this._timeFormats[e];
  };
  e.prototype.getCurrentTimeFormat = function () {
    return this._timeFormats[this.currentLocale];
  };
  return e;
}();
var localeStore = window.DekraLocaleStore = window.DekraLocaleStore || new LocaleStore();
function setDefaultLocale(e) {
  if (localeStore.availableLocales.indexOf(e) !== -1) {
    localeStore.defaultLocale = e;
  } else {
    throw new Error("[Translate - setDefaultLocale] No translations found for locale " + e);
  }
}
function useLocale(e) {
  if (localeStore.availableLocales.indexOf(e) !== -1) {
    localeStore.currentLocale = e;
  } else {
    throw new Error("[Translate - useLocale] No translations found for locale " + e);
  }
}
function getCurrentLocale() {
  return localeStore.currentLocale;
}
function setTranslations(e, t, n) {
  if (n === void 0) {
    n = true;
  }
  localeStore.setTranslations(e, t, n);
}
function getTranslations(e, t) {
  return localeStore.getTranslations(e, t);
}
function getComponentTranslations(e, t) {
  return localeStore.getComponentTranslations(e.tagName, t);
}
function getTranslation(e, t) {
  return localeStore.getTranslation(e, t);
}
export { setTranslations as a, getTranslations as b, getComponentTranslations as c, getTranslation as d, defaultTranslationsGerman as e, defaultTranslationsEnglish as f, getCurrentLocale as g, localeStore as l, setDefaultLocale as s, useLocale as u };