import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Injectable, createEnvironmentInjector, createComponent, Directive, Input, HostBinding, HostListener, InjectionToken, APP_INITIALIZER, NgModule } from '@angular/core';
import { __decorate } from 'tslib';
import { fromEvent, Subject } from 'rxjs';
import { DekraToasterServiceCore, raf } from '@dekra-ds/core';
import * as i1 from '@angular/forms';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { applyPolyfills, defineCustomElements } from '@dekra-ds/core/dist/loader';

/* eslint-disable */
/* tslint:disable */
const _c0 = ["*"];
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = val);
      },
      /**
       * In the event that proxyInputs is called
       * multiple times re-defining these inputs
       * will cause an error to be thrown. As a result
       * we set configurable: true to indicate these
       * properties can be changed.
       */
      configurable: true
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  events.forEach(eventName => instance[eventName] = fromEvent(el, eventName));
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let DekraAlert = class DekraAlert {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraAlert_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraAlert)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraAlert,
      selectors: [["dekra-alert"]],
      inputs: {
        type: "type"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraAlert_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraAlert = __decorate([ProxyCmp({
  inputs: ['type']
})], DekraAlert);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraAlert, [{
    type: Component,
    args: [{
      selector: 'dekra-alert',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['type']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraBadge = class DekraBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraBadge_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraBadge)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraBadge,
      selectors: [["dekra-badge"]],
      inputs: {
        color: "color",
        displayStyle: "displayStyle"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraBadge_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraBadge = __decorate([ProxyCmp({
  inputs: ['color', 'displayStyle']
})], DekraBadge);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraBadge, [{
    type: Component,
    args: [{
      selector: 'dekra-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'displayStyle']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraBreadcrumb = class DekraBreadcrumb {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraBreadcrumb_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraBreadcrumb)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraBreadcrumb,
      selectors: [["dekra-breadcrumb"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraBreadcrumb_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraBreadcrumb = __decorate([ProxyCmp({})], DekraBreadcrumb);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraBreadcrumb, [{
    type: Component,
    args: [{
      selector: 'dekra-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraBreadcrumbs = class DekraBreadcrumbs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraBreadcrumbs_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraBreadcrumbs)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraBreadcrumbs,
      selectors: [["dekra-breadcrumbs"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraBreadcrumbs_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraBreadcrumbs = __decorate([ProxyCmp({})], DekraBreadcrumbs);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraBreadcrumbs, [{
    type: Component,
    args: [{
      selector: 'dekra-breadcrumbs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraButton = class DekraButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraButton_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraButton,
      selectors: [["dekra-button"]],
      inputs: {
        autofocus: "autofocus",
        color: "color",
        disabled: "disabled",
        displayStyle: "displayStyle",
        type: "type"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraButton_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraButton = __decorate([ProxyCmp({
  inputs: ['autofocus', 'color', 'disabled', 'displayStyle', 'type']
})], DekraButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraButton, [{
    type: Component,
    args: [{
      selector: 'dekra-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'color', 'disabled', 'displayStyle', 'type']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraCard = class DekraCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraCard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraCard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraCard,
      selectors: [["dekra-card"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraCard_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraCard = __decorate([ProxyCmp({})], DekraCard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraCard, [{
    type: Component,
    args: [{
      selector: 'dekra-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraCardAction = class DekraCardAction {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraCardAction_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraCardAction)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraCardAction,
      selectors: [["dekra-card-action"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraCardAction_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraCardAction = __decorate([ProxyCmp({})], DekraCardAction);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraCardAction, [{
    type: Component,
    args: [{
      selector: 'dekra-card-action',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraCardContent = class DekraCardContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraCardContent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraCardContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraCardContent,
      selectors: [["dekra-card-content"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraCardContent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraCardContent = __decorate([ProxyCmp({})], DekraCardContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraCardContent, [{
    type: Component,
    args: [{
      selector: 'dekra-card-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraCardHeader = class DekraCardHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraCardHeader_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraCardHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraCardHeader,
      selectors: [["dekra-card-header"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraCardHeader_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraCardHeader = __decorate([ProxyCmp({})], DekraCardHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraCardHeader, [{
    type: Component,
    args: [{
      selector: 'dekra-card-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraCheckbox = class DekraCheckbox {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraChange', 'dekraBlur', 'dekraFocus']);
  }
  static {
    this.ɵfac = function DekraCheckbox_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraCheckbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraCheckbox,
      selectors: [["dekra-checkbox"]],
      inputs: {
        autofocus: "autofocus",
        checked: "checked",
        debounce: "debounce",
        disabled: "disabled",
        error: "error",
        hint: "hint",
        indeterminate: "indeterminate",
        label: "label",
        required: "required",
        validateOnLoad: "validateOnLoad"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraCheckbox_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraCheckbox = __decorate([ProxyCmp({
  inputs: ['autofocus', 'checked', 'debounce', 'disabled', 'error', 'hint', 'indeterminate', 'label', 'required', 'validateOnLoad'],
  methods: ['setFocus', 'getInputElement']
})], DekraCheckbox);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraCheckbox, [{
    type: Component,
    args: [{
      selector: 'dekra-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'checked', 'debounce', 'disabled', 'error', 'hint', 'indeterminate', 'label', 'required', 'validateOnLoad']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraContentNav = class DekraContentNav {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraContentNav_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraContentNav)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraContentNav,
      selectors: [["dekra-content-nav"]],
      inputs: {
        label: "label",
        topMargin: "topMargin"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraContentNav_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraContentNav = __decorate([ProxyCmp({
  inputs: ['label', 'topMargin']
})], DekraContentNav);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraContentNav, [{
    type: Component,
    args: [{
      selector: 'dekra-content-nav',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['label', 'topMargin']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraContentNavItem = class DekraContentNavItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraContentNavItem_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraContentNavItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraContentNavItem,
      selectors: [["dekra-content-nav-item"]],
      inputs: {
        elementId: "elementId",
        isActive: "isActive",
        label: "label"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraContentNavItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraContentNavItem = __decorate([ProxyCmp({
  inputs: ['elementId', 'isActive', 'label']
})], DekraContentNavItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraContentNavItem, [{
    type: Component,
    args: [{
      selector: 'dekra-content-nav-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['elementId', 'isActive', 'label']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraDatepicker = class DekraDatepicker {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraChange', 'dekraBlur', 'dekraFocus', 'dekraDecadeChange', 'dekraYearChange', 'dekraMonthChange', 'dekraDayChange', 'dekraHourChange', 'dekraMinuteChange']);
  }
  static {
    this.ɵfac = function DekraDatepicker_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraDatepicker)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraDatepicker,
      selectors: [["dekra-datepicker"]],
      inputs: {
        autofocus: "autofocus",
        condensed: "condensed",
        dateFormat: "dateFormat",
        daysPerWeek: "daysPerWeek",
        debounce: "debounce",
        disabled: "disabled",
        displayNow: "displayNow",
        displayToday: "displayToday",
        displayType: "displayType",
        error: "error",
        firstWeekday: "firstWeekday",
        hideLabel: "hideLabel",
        hint: "hint",
        label: "label",
        maxDate: "maxDate",
        maxTime: "maxTime",
        minDate: "minDate",
        minTime: "minTime",
        pickerType: "pickerType",
        placeholder: "placeholder",
        required: "required",
        step: "step",
        timeFormat: "timeFormat",
        validateOnLoad: "validateOnLoad",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraDatepicker_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraDatepicker = __decorate([ProxyCmp({
  inputs: ['autofocus', 'condensed', 'dateFormat', 'daysPerWeek', 'debounce', 'disabled', 'displayNow', 'displayToday', 'displayType', 'error', 'firstWeekday', 'hideLabel', 'hint', 'label', 'maxDate', 'maxTime', 'minDate', 'minTime', 'pickerType', 'placeholder', 'required', 'step', 'timeFormat', 'validateOnLoad', 'value'],
  methods: ['setFocus', 'getInputElement']
})], DekraDatepicker);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraDatepicker, [{
    type: Component,
    args: [{
      selector: 'dekra-datepicker',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'condensed', 'dateFormat', 'daysPerWeek', 'debounce', 'disabled', 'displayNow', 'displayToday', 'displayType', 'error', 'firstWeekday', 'hideLabel', 'hint', 'label', 'maxDate', 'maxTime', 'minDate', 'minTime', 'pickerType', 'placeholder', 'required', 'step', 'timeFormat', 'validateOnLoad', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraDialog = class DekraDialog {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraOpen', 'dekraClose']);
  }
  static {
    this.ɵfac = function DekraDialog_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraDialog)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraDialog,
      selectors: [["dekra-dialog"]],
      inputs: {
        closeOnEsc: "closeOnEsc",
        closeOnOutsideClick: "closeOnOutsideClick",
        customClass: "customClass"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraDialog_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraDialog = __decorate([ProxyCmp({
  inputs: ['closeOnEsc', 'closeOnOutsideClick', 'customClass'],
  methods: ['open', 'close']
})], DekraDialog);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraDialog, [{
    type: Component,
    args: [{
      selector: 'dekra-dialog',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['closeOnEsc', 'closeOnOutsideClick', 'customClass']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraDialogActions = class DekraDialogActions {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraDialogActions_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraDialogActions)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraDialogActions,
      selectors: [["dekra-dialog-actions"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraDialogActions_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraDialogActions = __decorate([ProxyCmp({})], DekraDialogActions);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraDialogActions, [{
    type: Component,
    args: [{
      selector: 'dekra-dialog-actions',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraDialogContent = class DekraDialogContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraDialogContent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraDialogContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraDialogContent,
      selectors: [["dekra-dialog-content"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraDialogContent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraDialogContent = __decorate([ProxyCmp({})], DekraDialogContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraDialogContent, [{
    type: Component,
    args: [{
      selector: 'dekra-dialog-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraDialogHeader = class DekraDialogHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraDialogHeader_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraDialogHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraDialogHeader,
      selectors: [["dekra-dialog-header"]],
      inputs: {
        showClose: "showClose"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraDialogHeader_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraDialogHeader = __decorate([ProxyCmp({
  inputs: ['showClose']
})], DekraDialogHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraDialogHeader, [{
    type: Component,
    args: [{
      selector: 'dekra-dialog-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['showClose']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraDivider = class DekraDivider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraDivider_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraDivider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraDivider,
      selectors: [["dekra-divider"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraDivider_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraDivider = __decorate([ProxyCmp({})], DekraDivider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraDivider, [{
    type: Component,
    args: [{
      selector: 'dekra-divider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraDropdown = class DekraDropdown {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraDropdown_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraDropdown)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraDropdown,
      selectors: [["dekra-dropdown"]],
      inputs: {
        displayStyle: "displayStyle",
        itemAlignment: "itemAlignment",
        label: "label"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraDropdown_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraDropdown = __decorate([ProxyCmp({
  inputs: ['displayStyle', 'itemAlignment', 'label']
})], DekraDropdown);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraDropdown, [{
    type: Component,
    args: [{
      selector: 'dekra-dropdown',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['displayStyle', 'itemAlignment', 'label']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraFileUpload = class DekraFileUpload {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraChange', 'dekraBlur', 'dekraFocus']);
  }
  static {
    this.ɵfac = function DekraFileUpload_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraFileUpload)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraFileUpload,
      selectors: [["dekra-file-upload"]],
      inputs: {
        accept: "accept",
        autofocus: "autofocus",
        clear: "clear",
        condensed: "condensed",
        debounce: "debounce",
        disabled: "disabled",
        error: "error",
        files: "files",
        hideLabel: "hideLabel",
        hint: "hint",
        label: "label",
        multiple: "multiple",
        onlyIcon: "onlyIcon",
        required: "required",
        size: "size",
        validateOnLoad: "validateOnLoad"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraFileUpload_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraFileUpload = __decorate([ProxyCmp({
  inputs: ['accept', 'autofocus', 'clear', 'condensed', 'debounce', 'disabled', 'error', 'files', 'hideLabel', 'hint', 'label', 'multiple', 'onlyIcon', 'required', 'size', 'validateOnLoad'],
  methods: ['setFocus', 'getInputElement']
})], DekraFileUpload);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraFileUpload, [{
    type: Component,
    args: [{
      selector: 'dekra-file-upload',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['accept', 'autofocus', 'clear', 'condensed', 'debounce', 'disabled', 'error', 'files', 'hideLabel', 'hint', 'label', 'multiple', 'onlyIcon', 'required', 'size', 'validateOnLoad']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraHeader = class DekraHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraHeader_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraHeader,
      selectors: [["dekra-header"]],
      inputs: {
        mobileThreshold: "mobileThreshold"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraHeader_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraHeader = __decorate([ProxyCmp({
  inputs: ['mobileThreshold'],
  methods: ['closeMobileHeader']
})], DekraHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraHeader, [{
    type: Component,
    args: [{
      selector: 'dekra-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['mobileThreshold']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraHeaderAction = class DekraHeaderAction {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraHeaderAction_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraHeaderAction)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraHeaderAction,
      selectors: [["dekra-header-action"]],
      inputs: {
        iconName: "iconName",
        iconSrc: "iconSrc",
        label: "label"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraHeaderAction_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraHeaderAction = __decorate([ProxyCmp({
  inputs: ['iconName', 'iconSrc', 'label']
})], DekraHeaderAction);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraHeaderAction, [{
    type: Component,
    args: [{
      selector: 'dekra-header-action',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['iconName', 'iconSrc', 'label']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraHeaderMenu = class DekraHeaderMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraHeaderMenu_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraHeaderMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraHeaderMenu,
      selectors: [["dekra-header-menu"]],
      inputs: {
        iconName: "iconName",
        iconSrc: "iconSrc",
        label: "label"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraHeaderMenu_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraHeaderMenu = __decorate([ProxyCmp({
  inputs: ['iconName', 'iconSrc', 'label']
})], DekraHeaderMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraHeaderMenu, [{
    type: Component,
    args: [{
      selector: 'dekra-header-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['iconName', 'iconSrc', 'label']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraHeaderMenuItem = class DekraHeaderMenuItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraHeaderMenuItem_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraHeaderMenuItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraHeaderMenuItem,
      selectors: [["dekra-header-menu-item"]],
      inputs: {
        closeOnClick: "closeOnClick"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraHeaderMenuItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraHeaderMenuItem = __decorate([ProxyCmp({
  inputs: ['closeOnClick']
})], DekraHeaderMenuItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraHeaderMenuItem, [{
    type: Component,
    args: [{
      selector: 'dekra-header-menu-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['closeOnClick']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraHeaderNavItem = class DekraHeaderNavItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraHeaderNavItem_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraHeaderNavItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraHeaderNavItem,
      selectors: [["dekra-header-nav-item"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraHeaderNavItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraHeaderNavItem = __decorate([ProxyCmp({})], DekraHeaderNavItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraHeaderNavItem, [{
    type: Component,
    args: [{
      selector: 'dekra-header-nav-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraHeaderSearch = class DekraHeaderSearch {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraHeaderSearch_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraHeaderSearch)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraHeaderSearch,
      selectors: [["dekra-header-search"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraHeaderSearch_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraHeaderSearch = __decorate([ProxyCmp({})], DekraHeaderSearch);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraHeaderSearch, [{
    type: Component,
    args: [{
      selector: 'dekra-header-search',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraIcon = class DekraIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraIcon_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraIcon)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraIcon,
      selectors: [["dekra-icon"]],
      inputs: {
        name: "name",
        src: "src",
        variant: "variant"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraIcon_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraIcon = __decorate([ProxyCmp({
  inputs: ['name', 'src', 'variant']
})], DekraIcon);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraIcon, [{
    type: Component,
    args: [{
      selector: 'dekra-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['name', 'src', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraInput = class DekraInput {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraChange', 'dekraBlur', 'dekraFocus']);
  }
  static {
    this.ɵfac = function DekraInput_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraInput)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraInput,
      selectors: [["dekra-input"]],
      inputs: {
        autofocus: "autofocus",
        condensed: "condensed",
        debounce: "debounce",
        disabled: "disabled",
        error: "error",
        hideLabel: "hideLabel",
        hint: "hint",
        label: "label",
        max: "max",
        maxlength: "maxlength",
        min: "min",
        minlength: "minlength",
        pattern: "pattern",
        placeholder: "placeholder",
        required: "required",
        showSpinner: "showSpinner",
        step: "step",
        type: "type",
        validateOnLoad: "validateOnLoad",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraInput_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraInput = __decorate([ProxyCmp({
  inputs: ['autofocus', 'condensed', 'debounce', 'disabled', 'error', 'hideLabel', 'hint', 'label', 'max', 'maxlength', 'min', 'minlength', 'pattern', 'placeholder', 'required', 'showSpinner', 'step', 'type', 'validateOnLoad', 'value'],
  methods: ['setFocus', 'getInputElement']
})], DekraInput);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraInput, [{
    type: Component,
    args: [{
      selector: 'dekra-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'condensed', 'debounce', 'disabled', 'error', 'hideLabel', 'hint', 'label', 'max', 'maxlength', 'min', 'minlength', 'pattern', 'placeholder', 'required', 'showSpinner', 'step', 'type', 'validateOnLoad', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraItem = class DekraItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraItem_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraItem,
      selectors: [["dekra-item"]],
      inputs: {
        dekraTabIndex: "dekraTabIndex"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraItem = __decorate([ProxyCmp({
  inputs: ['dekraTabIndex']
})], DekraItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraItem, [{
    type: Component,
    args: [{
      selector: 'dekra-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['dekraTabIndex']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraLabel = class DekraLabel {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraLabel_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraLabel)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraLabel,
      selectors: [["dekra-label"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraLabel_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraLabel = __decorate([ProxyCmp({})], DekraLabel);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraLabel, [{
    type: Component,
    args: [{
      selector: 'dekra-label',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraList = class DekraList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraList_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraList,
      selectors: [["dekra-list"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraList_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraList = __decorate([ProxyCmp({})], DekraList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraList, [{
    type: Component,
    args: [{
      selector: 'dekra-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraListDivider = class DekraListDivider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraListDivider_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraListDivider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraListDivider,
      selectors: [["dekra-list-divider"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraListDivider_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraListDivider = __decorate([ProxyCmp({})], DekraListDivider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraListDivider, [{
    type: Component,
    args: [{
      selector: 'dekra-list-divider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraListItem = class DekraListItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraListItem_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraListItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraListItem,
      selectors: [["dekra-list-item"]],
      inputs: {
        button: "button",
        href: "href",
        target: "target"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraListItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraListItem = __decorate([ProxyCmp({
  inputs: ['button', 'href', 'target']
})], DekraListItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraListItem, [{
    type: Component,
    args: [{
      selector: 'dekra-list-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['button', 'href', 'target']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraLoading = class DekraLoading {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraLoading_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraLoading)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraLoading,
      selectors: [["dekra-loading"]],
      inputs: {
        active: "active",
        debounce: "debounce",
        type: "type"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraLoading_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraLoading = __decorate([ProxyCmp({
  inputs: ['active', 'debounce', 'type']
})], DekraLoading);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraLoading, [{
    type: Component,
    args: [{
      selector: 'dekra-loading',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'debounce', 'type']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraLogo = class DekraLogo {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraLogo_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraLogo)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraLogo,
      selectors: [["dekra-logo"]],
      inputs: {
        color: "color",
        href: "href",
        target: "target",
        type: "type"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraLogo_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraLogo = __decorate([ProxyCmp({
  inputs: ['color', 'href', 'target', 'type']
})], DekraLogo);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraLogo, [{
    type: Component,
    args: [{
      selector: 'dekra-logo',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'href', 'target', 'type']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraPaging = class DekraPaging {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraStateChanged']);
  }
  static {
    this.ɵfac = function DekraPaging_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraPaging)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraPaging,
      selectors: [["dekra-paging"]],
      inputs: {
        page: "page",
        pageSize: "pageSize",
        pageSizes: "pageSizes",
        showElements: "showElements",
        showPageSizeSelection: "showPageSizeSelection",
        totalItems: "totalItems"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraPaging_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraPaging = __decorate([ProxyCmp({
  inputs: ['page', 'pageSize', 'pageSizes', 'showElements', 'showPageSizeSelection', 'totalItems']
})], DekraPaging);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraPaging, [{
    type: Component,
    args: [{
      selector: 'dekra-paging',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['page', 'pageSize', 'pageSizes', 'showElements', 'showPageSizeSelection', 'totalItems']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraRadioButton = class DekraRadioButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraBlur', 'dekraFocus']);
  }
  static {
    this.ɵfac = function DekraRadioButton_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraRadioButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraRadioButton,
      selectors: [["dekra-radio-button"]],
      inputs: {
        autofocus: "autofocus",
        checked: "checked",
        disabled: "disabled",
        hint: "hint",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraRadioButton_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraRadioButton = __decorate([ProxyCmp({
  inputs: ['autofocus', 'checked', 'disabled', 'hint', 'value'],
  methods: ['setFocus', 'getInputElement']
})], DekraRadioButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraRadioButton, [{
    type: Component,
    args: [{
      selector: 'dekra-radio-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'checked', 'disabled', 'hint', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraRadioButtonGroup = class DekraRadioButtonGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraChange']);
  }
  static {
    this.ɵfac = function DekraRadioButtonGroup_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraRadioButtonGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraRadioButtonGroup,
      selectors: [["dekra-radio-button-group"]],
      inputs: {
        alignment: "alignment",
        autofocus: "autofocus",
        debounce: "debounce",
        disabled: "disabled",
        error: "error",
        hint: "hint",
        label: "label",
        required: "required",
        validateOnLoad: "validateOnLoad",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraRadioButtonGroup_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraRadioButtonGroup = __decorate([ProxyCmp({
  inputs: ['alignment', 'autofocus', 'debounce', 'disabled', 'error', 'hint', 'label', 'required', 'validateOnLoad', 'value'],
  methods: ['setFocus']
})], DekraRadioButtonGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraRadioButtonGroup, [{
    type: Component,
    args: [{
      selector: 'dekra-radio-button-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alignment', 'autofocus', 'debounce', 'disabled', 'error', 'hint', 'label', 'required', 'validateOnLoad', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraSelect = class DekraSelect {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraChange', 'dekraBlur', 'dekraFocus']);
  }
  static {
    this.ɵfac = function DekraSelect_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraSelect)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraSelect,
      selectors: [["dekra-select"]],
      inputs: {
        autofocus: "autofocus",
        condensed: "condensed",
        debounce: "debounce",
        disabled: "disabled",
        error: "error",
        hideLabel: "hideLabel",
        hint: "hint",
        label: "label",
        placeholder: "placeholder",
        required: "required",
        validateOnLoad: "validateOnLoad",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraSelect_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraSelect = __decorate([ProxyCmp({
  inputs: ['autofocus', 'condensed', 'debounce', 'disabled', 'error', 'hideLabel', 'hint', 'label', 'placeholder', 'required', 'validateOnLoad', 'value'],
  methods: ['setFocus', 'getInputElement']
})], DekraSelect);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraSelect, [{
    type: Component,
    args: [{
      selector: 'dekra-select',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'condensed', 'debounce', 'disabled', 'error', 'hideLabel', 'hint', 'label', 'placeholder', 'required', 'validateOnLoad', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraSelectOption = class DekraSelectOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraSelectOption_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraSelectOption)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraSelectOption,
      selectors: [["dekra-select-option"]],
      inputs: {
        checked: "checked",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraSelectOption_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraSelectOption = __decorate([ProxyCmp({
  inputs: ['checked', 'value']
})], DekraSelectOption);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraSelectOption, [{
    type: Component,
    args: [{
      selector: 'dekra-select-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraShield = class DekraShield {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraShield_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraShield)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraShield,
      selectors: [["dekra-shield"]],
      inputs: {
        color: "color",
        href: "href",
        target: "target"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraShield_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraShield = __decorate([ProxyCmp({
  inputs: ['color', 'href', 'target']
})], DekraShield);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraShield, [{
    type: Component,
    args: [{
      selector: 'dekra-shield',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'href', 'target']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraSideNav = class DekraSideNav {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraSideNav_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraSideNav)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraSideNav,
      selectors: [["dekra-side-nav"]],
      inputs: {
        hideIcons: "hideIcons",
        mobileThreshold: "mobileThreshold",
        navId: "navId"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraSideNav_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraSideNav = __decorate([ProxyCmp({
  inputs: ['hideIcons', 'mobileThreshold', 'navId'],
  methods: ['toggleNavigation']
})], DekraSideNav);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraSideNav, [{
    type: Component,
    args: [{
      selector: 'dekra-side-nav',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['hideIcons', 'mobileThreshold', 'navId']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraSideNavContainer = class DekraSideNavContainer {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraSideNavContainer_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraSideNavContainer)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraSideNavContainer,
      selectors: [["dekra-side-nav-container"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraSideNavContainer_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraSideNavContainer = __decorate([ProxyCmp({})], DekraSideNavContainer);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraSideNavContainer, [{
    type: Component,
    args: [{
      selector: 'dekra-side-nav-container',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraSideNavItem = class DekraSideNavItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraSideNavItem_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraSideNavItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraSideNavItem,
      selectors: [["dekra-side-nav-item"]],
      inputs: {
        disableToggle: "disableToggle",
        hideIcon: "hideIcon",
        open: "open",
        showToggle: "showToggle"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraSideNavItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraSideNavItem = __decorate([ProxyCmp({
  inputs: ['disableToggle', 'hideIcon', 'open', 'showToggle']
})], DekraSideNavItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraSideNavItem, [{
    type: Component,
    args: [{
      selector: 'dekra-side-nav-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disableToggle', 'hideIcon', 'open', 'showToggle']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraSideNavSection = class DekraSideNavSection {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraSideNavSection_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraSideNavSection)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraSideNavSection,
      selectors: [["dekra-side-nav-section"]],
      inputs: {
        disableToggle: "disableToggle",
        open: "open",
        showToggle: "showToggle"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraSideNavSection_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraSideNavSection = __decorate([ProxyCmp({
  inputs: ['disableToggle', 'open', 'showToggle']
})], DekraSideNavSection);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraSideNavSection, [{
    type: Component,
    args: [{
      selector: 'dekra-side-nav-section',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disableToggle', 'open', 'showToggle']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraSlider = class DekraSlider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraChange', 'dekraBlur', 'dekraFocus']);
  }
  static {
    this.ɵfac = function DekraSlider_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraSlider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraSlider,
      selectors: [["dekra-slider"]],
      inputs: {
        activeBarStart: "activeBarStart",
        autofocus: "autofocus",
        debounce: "debounce",
        disabled: "disabled",
        dualKnobs: "dualKnobs",
        hideLabel: "hideLabel",
        hint: "hint",
        label: "label",
        max: "max",
        min: "min",
        step: "step",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraSlider_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraSlider = __decorate([ProxyCmp({
  inputs: ['activeBarStart', 'autofocus', 'debounce', 'disabled', 'dualKnobs', 'hideLabel', 'hint', 'label', 'max', 'min', 'step', 'value'],
  methods: ['setFocus', 'getInputElement']
})], DekraSlider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraSlider, [{
    type: Component,
    args: [{
      selector: 'dekra-slider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activeBarStart', 'autofocus', 'debounce', 'disabled', 'dualKnobs', 'hideLabel', 'hint', 'label', 'max', 'min', 'step', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraStep = class DekraStep {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraStep_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraStep)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraStep,
      selectors: [["dekra-step"]],
      inputs: {
        disabled: "disabled"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraStep_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraStep = __decorate([ProxyCmp({
  inputs: ['disabled']
})], DekraStep);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraStep, [{
    type: Component,
    args: [{
      selector: 'dekra-step',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraStepper = class DekraStepper {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraStepper_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraStepper)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraStepper,
      selectors: [["dekra-stepper"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraStepper_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraStepper = __decorate([ProxyCmp({})], DekraStepper);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraStepper, [{
    type: Component,
    args: [{
      selector: 'dekra-stepper',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraSublabel = class DekraSublabel {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraSublabel_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraSublabel)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraSublabel,
      selectors: [["dekra-sublabel"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraSublabel_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraSublabel = __decorate([ProxyCmp({})], DekraSublabel);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraSublabel, [{
    type: Component,
    args: [{
      selector: 'dekra-sublabel',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTab = class DekraTab {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraTab_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTab)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTab,
      selectors: [["dekra-tab"]],
      inputs: {
        active: "active",
        tab: "tab"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTab_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTab = __decorate([ProxyCmp({
  inputs: ['active', 'tab']
})], DekraTab);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTab, [{
    type: Component,
    args: [{
      selector: 'dekra-tab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'tab']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTabContent = class DekraTabContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraTabContent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTabContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTabContent,
      selectors: [["dekra-tab-content"]],
      inputs: {
        active: "active",
        tab: "tab"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTabContent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTabContent = __decorate([ProxyCmp({
  inputs: ['active', 'tab']
})], DekraTabContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTabContent, [{
    type: Component,
    args: [{
      selector: 'dekra-tab-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'tab']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTable = class DekraTable {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraSelect']);
  }
  static {
    this.ɵfac = function DekraTable_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTable)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTable,
      selectors: [["dekra-table"]],
      inputs: {
        config: "config",
        data: "data"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTable_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTable = __decorate([ProxyCmp({
  inputs: ['config', 'data'],
  methods: ['getCurrentConfig']
})], DekraTable);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTable, [{
    type: Component,
    args: [{
      selector: 'dekra-table',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['config', 'data']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTabs = class DekraTabs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraSelect']);
  }
  static {
    this.ɵfac = function DekraTabs_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTabs)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTabs,
      selectors: [["dekra-tabs"]],
      inputs: {
        default: "default",
        display: "display"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTabs_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTabs = __decorate([ProxyCmp({
  inputs: ['default', 'display'],
  methods: ['setActive']
})], DekraTabs);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTabs, [{
    type: Component,
    args: [{
      selector: 'dekra-tabs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['default', 'display']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTag = class DekraTag {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraDelete']);
  }
  static {
    this.ɵfac = function DekraTag_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTag)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTag,
      selectors: [["dekra-tag"]],
      inputs: {
        color: "color",
        displayStyle: "displayStyle",
        identifier: "identifier",
        label: "label",
        removable: "removable"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTag_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTag = __decorate([ProxyCmp({
  inputs: ['color', 'displayStyle', 'identifier', 'label', 'removable']
})], DekraTag);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTag, [{
    type: Component,
    args: [{
      selector: 'dekra-tag',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'displayStyle', 'identifier', 'label', 'removable']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTagList = class DekraTagList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraChange']);
  }
  static {
    this.ɵfac = function DekraTagList_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTagList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTagList,
      selectors: [["dekra-tag-list"]],
      inputs: {
        color: "color",
        disabled: "disabled",
        displayStyle: "displayStyle",
        error: "error",
        hideLabel: "hideLabel",
        hint: "hint",
        label: "label",
        removable: "removable",
        required: "required",
        values: "values"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTagList_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTagList = __decorate([ProxyCmp({
  inputs: ['color', 'disabled', 'displayStyle', 'error', 'hideLabel', 'hint', 'label', 'removable', 'required', 'values']
})], DekraTagList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTagList, [{
    type: Component,
    args: [{
      selector: 'dekra-tag-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'disabled', 'displayStyle', 'error', 'hideLabel', 'hint', 'label', 'removable', 'required', 'values']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTextarea = class DekraTextarea {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraChange', 'dekraBlur', 'dekraFocus']);
  }
  static {
    this.ɵfac = function DekraTextarea_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTextarea)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTextarea,
      selectors: [["dekra-textarea"]],
      inputs: {
        autoResize: "autoResize",
        autofocus: "autofocus",
        condensed: "condensed",
        debounce: "debounce",
        disabled: "disabled",
        error: "error",
        hideLabel: "hideLabel",
        hint: "hint",
        label: "label",
        maxlength: "maxlength",
        minlength: "minlength",
        placeholder: "placeholder",
        required: "required",
        resizable: "resizable",
        rows: "rows",
        validateOnLoad: "validateOnLoad",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTextarea_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTextarea = __decorate([ProxyCmp({
  inputs: ['autoResize', 'autofocus', 'condensed', 'debounce', 'disabled', 'error', 'hideLabel', 'hint', 'label', 'maxlength', 'minlength', 'placeholder', 'required', 'resizable', 'rows', 'validateOnLoad', 'value'],
  methods: ['setFocus', 'getInputElement']
})], DekraTextarea);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTextarea, [{
    type: Component,
    args: [{
      selector: 'dekra-textarea',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoResize', 'autofocus', 'condensed', 'debounce', 'disabled', 'error', 'hideLabel', 'hint', 'label', 'maxlength', 'minlength', 'placeholder', 'required', 'resizable', 'rows', 'validateOnLoad', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraToast = class DekraToast {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraToast_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraToast)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraToast,
      selectors: [["dekra-toast"]],
      inputs: {
        hideIcon: "hideIcon",
        horizontalAlignment: "horizontalAlignment",
        message: "message",
        type: "type",
        verticalAlignment: "verticalAlignment"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraToast_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraToast = __decorate([ProxyCmp({
  inputs: ['hideIcon', 'horizontalAlignment', 'message', 'type', 'verticalAlignment'],
  methods: ['setConfig', 'displayToast', 'hideToast']
})], DekraToast);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraToast, [{
    type: Component,
    args: [{
      selector: 'dekra-toast',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['hideIcon', 'horizontalAlignment', 'message', 'type', 'verticalAlignment']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraToggle = class DekraToggle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraChange', 'dekraBlur', 'dekraFocus']);
  }
  static {
    this.ɵfac = function DekraToggle_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraToggle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraToggle,
      selectors: [["dekra-toggle"]],
      inputs: {
        autofocus: "autofocus",
        checked: "checked",
        debounce: "debounce",
        disabled: "disabled",
        error: "error",
        hint: "hint",
        label: "label",
        required: "required",
        validateOnLoad: "validateOnLoad"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraToggle_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraToggle = __decorate([ProxyCmp({
  inputs: ['autofocus', 'checked', 'debounce', 'disabled', 'error', 'hint', 'label', 'required', 'validateOnLoad'],
  methods: ['setFocus', 'getInputElement']
})], DekraToggle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraToggle, [{
    type: Component,
    args: [{
      selector: 'dekra-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'checked', 'debounce', 'disabled', 'error', 'hint', 'label', 'required', 'validateOnLoad']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTooltip = class DekraTooltip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraTooltip_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTooltip)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTooltip,
      selectors: [["dekra-tooltip"]],
      inputs: {
        debounce: "debounce"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTooltip_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTooltip = __decorate([ProxyCmp({
  inputs: ['debounce']
})], DekraTooltip);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTooltip, [{
    type: Component,
    args: [{
      selector: 'dekra-tooltip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['debounce']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTooltipContent = class DekraTooltipContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraTooltipContent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTooltipContent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTooltipContent,
      selectors: [["dekra-tooltip-content"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTooltipContent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTooltipContent = __decorate([ProxyCmp({})], DekraTooltipContent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTooltipContent, [{
    type: Component,
    args: [{
      selector: 'dekra-tooltip-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTree = class DekraTree {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraSelect']);
  }
  static {
    this.ɵfac = function DekraTree_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTree)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTree,
      selectors: [["dekra-tree"]],
      inputs: {
        childProperty: "childProperty",
        onlyChecked: "onlyChecked",
        selectable: "selectable"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTree_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTree = __decorate([ProxyCmp({
  inputs: ['childProperty', 'onlyChecked', 'selectable'],
  methods: ['handleChildSelect']
})], DekraTree);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTree, [{
    type: Component,
    args: [{
      selector: 'dekra-tree',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['childProperty', 'onlyChecked', 'selectable']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTreeItem = class DekraTreeItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraToggle', 'dekraSelect', 'dekraClick']);
  }
  static {
    this.ɵfac = function DekraTreeItem_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTreeItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTreeItem,
      selectors: [["dekra-tree-item"]],
      inputs: {
        checked: "checked",
        childProperty: "childProperty",
        data: "data",
        indeterminate: "indeterminate",
        open: "open",
        selectable: "selectable",
        showToggle: "showToggle"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTreeItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTreeItem = __decorate([ProxyCmp({
  inputs: ['checked', 'childProperty', 'data', 'indeterminate', 'open', 'selectable', 'showToggle']
})], DekraTreeItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTreeItem, [{
    type: Component,
    args: [{
      selector: 'dekra-tree-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'childProperty', 'data', 'indeterminate', 'open', 'selectable', 'showToggle']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTriangle = class DekraTriangle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function DekraTriangle_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTriangle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTriangle,
      selectors: [["dekra-triangle"]],
      inputs: {
        color: "color",
        href: "href",
        target: "target"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTriangle_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTriangle = __decorate([ProxyCmp({
  inputs: ['color', 'href', 'target']
})], DekraTriangle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTriangle, [{
    type: Component,
    args: [{
      selector: 'dekra-triangle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'href', 'target']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let DekraTypeahead = class DekraTypeahead {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['dekraChange', 'dekraBlur', 'dekraFocus']);
  }
  static {
    this.ɵfac = function DekraTypeahead_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraTypeahead)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraTypeahead,
      selectors: [["dekra-typeahead"]],
      inputs: {
        autofocus: "autofocus",
        condensed: "condensed",
        customInput: "customInput",
        debounce: "debounce",
        disabled: "disabled",
        displayProperty: "displayProperty",
        error: "error",
        hideLabel: "hideLabel",
        hint: "hint",
        items: "items",
        label: "label",
        maxlength: "maxlength",
        minlength: "minlength",
        openOnFocus: "openOnFocus",
        placeholder: "placeholder",
        required: "required",
        validateOnLoad: "validateOnLoad",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function DekraTypeahead_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
DekraTypeahead = __decorate([ProxyCmp({
  inputs: ['autofocus', 'condensed', 'customInput', 'debounce', 'disabled', 'displayProperty', 'error', 'hideLabel', 'hint', 'items', 'label', 'maxlength', 'minlength', 'openOnFocus', 'placeholder', 'required', 'validateOnLoad', 'value'],
  methods: ['setFocus', 'getInputElement', 'collapsePopUp', 'expandPopUp']
})], DekraTypeahead);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraTypeahead, [{
    type: Component,
    args: [{
      selector: 'dekra-typeahead',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'condensed', 'customInput', 'debounce', 'disabled', 'displayProperty', 'error', 'hideLabel', 'hint', 'items', 'label', 'maxlength', 'minlength', 'openOnFocus', 'placeholder', 'required', 'validateOnLoad', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
class DekraDialogContainerComponent {
  static {
    this.ɵfac = function DekraDialogContainerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraDialogContainerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DekraDialogContainerComponent,
      selectors: [["ng-component"]],
      ngContentSelectors: _c0,
      decls: 2,
      vars: 0,
      template: function DekraDialogContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "dekra-dialog");
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [DekraDialog],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraDialogContainerComponent, [{
    type: Component,
    args: [{
      template: '<dekra-dialog><ng-content></ng-content></dekra-dialog>'
    }]
  }], null, null);
})();
class DekraDialogRef {
  constructor() {
    this.closeSubject = new Subject();
  }
  /**
   * Closes the dialog
   * @param dialogResult result which is emitted after the dialog is closed
   */
  close(dialogResult) {
    this.result = dialogResult;
    this.closeSubject.next(this.result);
    this.closeSubject.complete();
    if (this.nativeElement) {
      this.nativeElement.close();
      this.nativeElement.remove();
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
  /**
   * Observable for the using applications to listen for the result after the dialog was closed
   */
  onClosed() {
    return this.closeSubject;
  }
  static {
    this.ɵfac = function DekraDialogRef_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraDialogRef)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DekraDialogRef,
      factory: DekraDialogRef.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraDialogRef, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class DekraDialogService {
  constructor(envInjector, appRef) {
    this.envInjector = envInjector;
    this.appRef = appRef;
  }
  /**
   * Opens the dialog
   * @param component Angular component as dialog content
   * @param config DekraDialogConfig
   * @param data any Data will be passed as @Input data: D to the dialog component
   */
  show(component, config, data) {
    const dialogRef = new DekraDialogRef();
    const dialogInjector = createEnvironmentInjector([{
      provide: DekraDialogRef,
      useValue: dialogRef
    }], this.envInjector);
    const componentRef = createComponent(component, {
      environmentInjector: dialogInjector
    });
    this.appRef.attachView(componentRef.hostView);
    const dialogContainerRef = createComponent(DekraDialogContainerComponent, {
      environmentInjector: this.envInjector,
      projectableNodes: [componentRef.location.nativeElement.childNodes]
    });
    this.appRef.attachView(dialogContainerRef.hostView);
    const dekraDialog = dialogContainerRef.location.nativeElement.childNodes[0];
    dekraDialog.addEventListener('dekraClose', dialogResult => dialogRef.close(dialogResult.detail), {
      once: true
    });
    document.body.appendChild(dekraDialog);
    if (config && config.customClass) {
      dekraDialog.classList.add(config.customClass);
    }
    dialogRef.nativeElement = dekraDialog;
    dialogRef.componentRef = componentRef;
    dialogRef.componentInstance = dialogRef.componentRef.instance;
    if (data) {
      dialogRef.componentInstance.data = data;
    }
    componentRef.onDestroy(() => {
      dialogContainerRef.destroy();
      this.appRef.detachView(componentRef.hostView);
      this.appRef.detachView(dialogContainerRef.hostView);
    });
    dekraDialog.open(config);
    return dialogRef;
  }
  static {
    this.ɵfac = function DekraDialogService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraDialogService)(i0.ɵɵinject(i0.EnvironmentInjector), i0.ɵɵinject(i0.ApplicationRef));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DekraDialogService,
      factory: DekraDialogService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraDialogService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.EnvironmentInjector
  }, {
    type: i0.ApplicationRef
  }], null);
})();

/**
 * Wrapper for the internal toaster service
 */
class DekraToasterService extends DekraToasterServiceCore {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵDekraToasterService_BaseFactory;
      return function DekraToasterService_Factory(__ngFactoryType__) {
        return (ɵDekraToasterService_BaseFactory || (ɵDekraToasterService_BaseFactory = i0.ɵɵgetInheritedFactory(DekraToasterService)))(__ngFactoryType__ || DekraToasterService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DekraToasterService,
      factory: DekraToasterService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraToasterService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Angular Directive to connect Angular validators and their occurring error messages
 * with the internal web component error handling.
 * The Directive listens for status changes of the form control and reflects them to the web components.
 */
class DekraInputDirective {
  constructor(ngControl) {
    this.ngControl = ngControl;
    this.required = false;
  }
  ngOnInit() {
    if (!!this.ngControl && this.ngControl.control) {
      if (this.ngControl.control.validator) {
        const validator = this.ngControl.control.validator({});
        if (validator && validator['required']) {
          this.required = true;
        }
      }
      if (!this.error && this.error !== false) {
        this.validationErrors = this.ngControl.errors;
        this.ngControl.statusChanges?.subscribe(() => this.validationErrors = this.ngControl.errors);
      } else {
        this.validationErrors = this.error;
      }
    }
  }
  static {
    this.ɵfac = function DekraInputDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraInputDirective)(i0.ɵɵdirectiveInject(i1.NgControl));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DekraInputDirective,
      selectors: [["", "dekraInput", ""]],
      hostVars: 2,
      hostBindings: function DekraInputDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("error", ctx.validationErrors)("required", ctx.required);
        }
      },
      inputs: {
        error: "error"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraInputDirective, [{
    type: Directive,
    args: [{
      selector: '[dekraInput]'
    }]
  }], () => [{
    type: i1.NgControl
  }], {
    error: [{
      type: Input
    }],
    validationErrors: [{
      type: HostBinding,
      args: ['error']
    }],
    required: [{
      type: HostBinding,
      args: ['required']
    }]
  });
})();

/**
 * Copied and adapted from Ionic
 */
class ValueAccessor {
  constructor(injector, el) {
    this.injector = injector;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  writeValue(value) {
    if (value !== this.lastValue) {
      this.el.nativeElement.value = this.lastValue = value;
      setDekraClasses(this.el);
    }
  }
  handleBlurEvent(el) {
    if (el === this.el.nativeElement) {
      this.onTouched();
    }
  }
  handleChangeEvent(el, value) {
    if (el === this.el.nativeElement) {
      if (value !== this.lastValue) {
        this.lastValue = value;
        this.onChange(value);
      }
      setDekraClasses(this.el);
    }
  }
  setDisabledState(isDisabled) {
    this.el.nativeElement.disabled = isDisabled;
  }
  ngOnDestroy() {
    if (this.statusChanges) {
      this.statusChanges.unsubscribe();
    }
  }
  _handleBlurEvent(el) {
    if (el === this.el.nativeElement) {
      this.onTouched();
      setDekraClasses(this.el);
    }
  }
  ngAfterViewInit() {
    let ngControl;
    try {
      ngControl = this.injector.get(NgControl);
    } catch (e) {
      /* No FormControl or ngModel binding */
    }
    if (!ngControl) {
      return;
    }
    // Listen for changes in validity, disabled, or pending states
    if (ngControl.statusChanges) {
      this.statusChanges = ngControl.statusChanges.subscribe(() => setDekraClasses(this.el));
    }
    /**
     * Copied patch from Ionic:
     * TODO Remove this in favor of https://github.com/angular/angular/issues/10887
     * whenever it is implemented. Currently, Ionic's form status classes
     * do not react to changes when developers manually call
     * Angular form control methods such as markAsTouched.
     * This results in Ionic's form status classes being out
     * of sync with the ng form status classes.
     * This patches the methods to manually sync
     * the classes until this feature is implemented in Angular.
     */
    const formControl = ngControl.control;
    if (formControl) {
      const methodsToPatch = ['markAsTouched', 'markAllAsTouched', 'markAsUntouched', 'markAsDirty', 'markAsPristine'];
      methodsToPatch.forEach(method => {
        if (typeof formControl[method] !== 'undefined') {
          const oldFn = formControl[method].bind(formControl);
          formControl[method] = (...params) => {
            oldFn(...params);
            setDekraClasses(this.el);
          };
        }
      });
    }
  }
  static {
    this.ɵfac = function ValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ValueAccessor)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ValueAccessor,
      hostBindings: function ValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dekraBlur", function ValueAccessor_dekraBlur_HostBindingHandler($event) {
            return ctx._handleBlurEvent($event.target);
          });
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ValueAccessor, [{
    type: Directive
  }], () => [{
    type: i0.Injector
  }, {
    type: i0.ElementRef
  }], {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['dekraBlur', ['$event.target']]
    }]
  });
})();
const setDekraClasses = element => {
  raf(() => {
    const input = element.nativeElement;
    const classes = getClasses(input);
    setClasses(input, classes);
  });
};
const getClasses = element => {
  const classList = element.classList;
  const classes = [];
  for (let i = 0; i < classList.length; i++) {
    const item = classList.item(i);
    if (item !== null && startsWith(item, 'ng-')) {
      classes.push(`dekra-${item.substring(3)}`);
    }
  }
  return classes;
};
const setClasses = (element, classes) => {
  const classList = element.classList;
  ['dekra-valid', 'dekra-invalid', 'dekra-touched', 'dekra-untouched', 'dekra-dirty', 'dekra-pristine'].forEach(c => classList.remove(c));
  classes.forEach(c => classList.add(c));
};
const startsWith = (input, search) => {
  return input.substring(0, search.length) === search;
};
class SelectValueAccessor extends ValueAccessor {
  constructor(el, injector) {
    super(injector, el);
  }
  handleDekraBlur(el) {
    this.handleBlurEvent(el);
  }
  handleDekraChange(el) {
    this.handleChangeEvent(el, el.value);
  }
  static {
    this.ɵfac = function SelectValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SelectValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Injector));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SelectValueAccessor,
      selectors: [["dekra-radio-button-group"], ["dekra-select"], ["dekra-slider"], ["dekra-typeahead"]],
      hostBindings: function SelectValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dekraBlur", function SelectValueAccessor_dekraBlur_HostBindingHandler($event) {
            return ctx.handleDekraBlur($event.target);
          })("dekraChange", function SelectValueAccessor_dekraChange_HostBindingHandler($event) {
            return ctx.handleDekraChange($event.target);
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: SelectValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SelectValueAccessor, [{
    type: Directive,
    args: [{
      selector: 'dekra-radio-button-group, dekra-select, dekra-slider, dekra-typeahead',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: SelectValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Injector
  }], {
    handleDekraBlur: [{
      type: HostListener,
      args: ['dekraBlur', ['$event.target']]
    }],
    handleDekraChange: [{
      type: HostListener,
      args: ['dekraChange', ['$event.target']]
    }]
  });
})();
class DateValueAccessor extends ValueAccessor {
  constructor(el, injector) {
    super(injector, el);
  }
  handleDekraBlur(el) {
    this.handleBlurEvent(el);
  }
  handleDekraChange(el) {
    this.handleChangeEvent(el, el.value);
  }
  static {
    this.ɵfac = function DateValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DateValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Injector));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DateValueAccessor,
      selectors: [["dekra-datepicker"]],
      hostBindings: function DateValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dekraBlur", function DateValueAccessor_dekraBlur_HostBindingHandler($event) {
            return ctx.handleDekraBlur($event.target);
          })("dekraChange", function DateValueAccessor_dekraChange_HostBindingHandler($event) {
            return ctx.handleDekraChange($event.target);
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: DateValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateValueAccessor, [{
    type: Directive,
    args: [{
      selector: 'dekra-datepicker',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: DateValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Injector
  }], {
    handleDekraBlur: [{
      type: HostListener,
      args: ['dekraBlur', ['$event.target']]
    }],
    handleDekraChange: [{
      type: HostListener,
      args: ['dekraChange', ['$event.target']]
    }]
  });
})();
class FileValueAccessor extends ValueAccessor {
  constructor(el, injector) {
    super(injector, el);
  }
  handleDekraBlur(el) {
    this.handleBlurEvent(el);
  }
  handleDekraChange(el) {
    this.handleChangeEvent(el, el.files);
  }
  static {
    this.ɵfac = function FileValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FileValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Injector));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FileValueAccessor,
      selectors: [["dekra-file-upload"]],
      hostBindings: function FileValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dekraBlur", function FileValueAccessor_dekraBlur_HostBindingHandler($event) {
            return ctx.handleDekraBlur($event.target);
          })("dekraChange", function FileValueAccessor_dekraChange_HostBindingHandler($event) {
            return ctx.handleDekraChange($event.target);
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: FileValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileValueAccessor, [{
    type: Directive,
    args: [{
      selector: 'dekra-file-upload',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: FileValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Injector
  }], {
    handleDekraBlur: [{
      type: HostListener,
      args: ['dekraBlur', ['$event.target']]
    }],
    handleDekraChange: [{
      type: HostListener,
      args: ['dekraChange', ['$event.target']]
    }]
  });
})();
class BooleanValueAccessor extends ValueAccessor {
  constructor(el, injector) {
    super(injector, el);
  }
  writeValue(value) {
    this.el.nativeElement.checked = !!value;
    this.lastValue = !!value;
  }
  handleDekraChange(el) {
    this.handleChangeEvent(el, el.checked);
  }
  static {
    this.ɵfac = function BooleanValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BooleanValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Injector));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BooleanValueAccessor,
      selectors: [["dekra-checkbox"], ["dekra-radio-button"], ["dekra-toggle"]],
      hostBindings: function BooleanValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dekraChange", function BooleanValueAccessor_dekraChange_HostBindingHandler($event) {
            return ctx.handleDekraChange($event.target);
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BooleanValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BooleanValueAccessor, [{
    type: Directive,
    args: [{
      selector: 'dekra-checkbox, dekra-radio-button, dekra-toggle',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BooleanValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Injector
  }], {
    handleDekraChange: [{
      type: HostListener,
      args: ['dekraChange', ['$event.target']]
    }]
  });
})();
class NumberValueAccessor extends ValueAccessor {
  constructor(el, injector) {
    super(injector, el);
  }
  handleDekraBlur(el) {
    this.handleBlurEvent(el);
  }
  handleDekraChange(el) {
    const value = el.value;
    this.handleChangeEvent(el, value === undefined || value === null || value === '' ? null : parseFloat(value));
  }
  static {
    this.ɵfac = function NumberValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NumberValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Injector));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NumberValueAccessor,
      selectors: [["dekra-input", "type", "number"]],
      hostBindings: function NumberValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dekraBlur", function NumberValueAccessor_dekraBlur_HostBindingHandler($event) {
            return ctx.handleDekraBlur($event.target);
          })("dekraChange", function NumberValueAccessor_dekraChange_HostBindingHandler($event) {
            return ctx.handleDekraChange($event.target);
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: NumberValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumberValueAccessor, [{
    type: Directive,
    args: [{
      selector: 'dekra-input[type=number]',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: NumberValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Injector
  }], {
    handleDekraBlur: [{
      type: HostListener,
      args: ['dekraBlur', ['$event.target']]
    }],
    handleDekraChange: [{
      type: HostListener,
      args: ['dekraChange', ['$event.target']]
    }]
  });
})();
class TextValueAccessor extends ValueAccessor {
  constructor(el, injector) {
    super(injector, el);
  }
  handleDekraBlur(el) {
    this.handleBlurEvent(el);
  }
  handleDekraChange(el) {
    this.handleChangeEvent(el, el.value);
  }
  static {
    this.ɵfac = function TextValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TextValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Injector));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TextValueAccessor,
      selectors: [["dekra-input", 3, "type", "number"], ["dekra-textarea"]],
      hostBindings: function TextValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dekraBlur", function TextValueAccessor_dekraBlur_HostBindingHandler($event) {
            return ctx.handleDekraBlur($event.target);
          })("dekraChange", function TextValueAccessor_dekraChange_HostBindingHandler($event) {
            return ctx.handleDekraChange($event.target);
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TextValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextValueAccessor, [{
    type: Directive,
    args: [{
      selector: 'dekra-input:not([type=number]), dekra-textarea',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TextValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Injector
  }], {
    handleDekraBlur: [{
      type: HostListener,
      args: ['dekraBlur', ['$event.target']]
    }],
    handleDekraChange: [{
      type: HostListener,
      args: ['dekraChange', ['$event.target']]
    }]
  });
})();
class ArrayValueAccessor extends ValueAccessor {
  constructor(el, injector) {
    super(injector, el);
  }
  handleDekraBlur(el) {
    this.handleBlurEvent(el);
  }
  handleDekraChange(el) {
    this.handleChangeEvent(el, el.values);
  }
  writeValue(value) {
    if (value !== this.lastValue) {
      this.el.nativeElement.values = value;
      this.lastValue = value;
    }
  }
  static {
    this.ɵfac = function ArrayValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ArrayValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Injector));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ArrayValueAccessor,
      selectors: [["dekra-tag-list"]],
      hostBindings: function ArrayValueAccessor_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dekraBlur", function ArrayValueAccessor_dekraBlur_HostBindingHandler($event) {
            return ctx.handleDekraBlur($event.target);
          })("dekraChange", function ArrayValueAccessor_dekraChange_HostBindingHandler($event) {
            return ctx.handleDekraChange($event.target);
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: ArrayValueAccessor,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ArrayValueAccessor, [{
    type: Directive,
    args: [{
      selector: 'dekra-tag-list',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: ArrayValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Injector
  }], {
    handleDekraBlur: [{
      type: HostListener,
      args: ['dekraBlur', ['$event.target']]
    }],
    handleDekraChange: [{
      type: HostListener,
      args: ['dekraChange', ['$event.target']]
    }]
  });
})();
const DIRECTIVES = [DekraAlert, DekraBadge, DekraBreadcrumb, DekraBreadcrumbs, DekraButton, DekraCard, DekraCardAction, DekraCardContent, DekraCardHeader, DekraCheckbox, DekraContentNav, DekraContentNavItem, DekraDatepicker, DekraDialog, DekraDialogActions, DekraDialogContent, DekraDialogHeader, DekraDivider, DekraDropdown, DekraFileUpload, DekraHeader, DekraHeaderAction, DekraHeaderMenu, DekraHeaderMenuItem, DekraHeaderNavItem, DekraHeaderSearch, DekraIcon, DekraInput, DekraItem, DekraLabel, DekraList, DekraListDivider, DekraListItem, DekraLoading, DekraLogo, DekraPaging, DekraRadioButton, DekraRadioButtonGroup, DekraSelect, DekraSelectOption, DekraShield, DekraSideNav, DekraSideNavContainer, DekraSideNavItem, DekraSideNavSection, DekraSlider, DekraStep, DekraStepper, DekraSublabel, DekraTab, DekraTabContent, DekraTable, DekraTabs, DekraTag, DekraTagList, DekraTextarea, DekraToast, DekraToggle, DekraTooltip, DekraTooltipContent, DekraTree, DekraTreeItem, DekraTriangle, DekraTypeahead];
let didInitialize = false;
const ConfigToken = new InjectionToken('USERCONFIG');
/**
 * Initialize app and inject the externally defined configuration
 * @param config configuration for the design system
 * @param doc global document
 */
const appInitialize = (config, doc) => {
  return () => {
    const win = doc.defaultView;
    if (win && typeof window !== 'undefined') {
      if (didInitialize) {
        console.warn('Dekra Design System Angular was already initialized. Make sure DekraDesignSystemModule.forRoot() is just called once.');
      }
      didInitialize = true;
      const DekraDS = win.DekraDS = win.DekraDS || {};
      DekraDS.config = {
        ...config
      };
      return applyPolyfills().then(() => {
        return defineCustomElements(win);
      });
    }
  };
};
class DekraDesignSystemModule {
  static forRoot(config) {
    return {
      ngModule: DekraDesignSystemModule,
      providers: [{
        provide: ConfigToken,
        useValue: config
      }, {
        provide: APP_INITIALIZER,
        useFactory: appInitialize,
        multi: true,
        deps: [ConfigToken, DOCUMENT]
      }]
    };
  }
  static {
    this.ɵfac = function DekraDesignSystemModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DekraDesignSystemModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: DekraDesignSystemModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraDesignSystemModule, [{
    type: NgModule,
    args: [{
      declarations: [...DIRECTIVES, BooleanValueAccessor, DateValueAccessor, FileValueAccessor, SelectValueAccessor, TextValueAccessor, ArrayValueAccessor, NumberValueAccessor, DekraDialogContainerComponent, DekraInputDirective],
      exports: [...DIRECTIVES, BooleanValueAccessor, DateValueAccessor, FileValueAccessor, SelectValueAccessor, TextValueAccessor, ArrayValueAccessor, NumberValueAccessor, DekraDialogContainerComponent, DekraInputDirective],
      imports: [],
      providers: []
    }]
  }], null, null);
})();

/*
 * Public API Surface of web-styleguide
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ArrayValueAccessor, BooleanValueAccessor, DIRECTIVES, DateValueAccessor, DekraAlert, DekraBadge, DekraBreadcrumb, DekraBreadcrumbs, DekraButton, DekraCard, DekraCardAction, DekraCardContent, DekraCardHeader, DekraCheckbox, DekraContentNav, DekraContentNavItem, DekraDatepicker, DekraDesignSystemModule, DekraDialog, DekraDialogActions, DekraDialogContainerComponent, DekraDialogContent, DekraDialogHeader, DekraDialogRef, DekraDialogService, DekraDivider, DekraDropdown, DekraFileUpload, DekraHeader, DekraHeaderAction, DekraHeaderMenu, DekraHeaderMenuItem, DekraHeaderNavItem, DekraHeaderSearch, DekraIcon, DekraInput, DekraInputDirective, DekraItem, DekraLabel, DekraList, DekraListDivider, DekraListItem, DekraLoading, DekraLogo, DekraPaging, DekraRadioButton, DekraRadioButtonGroup, DekraSelect, DekraSelectOption, DekraShield, DekraSideNav, DekraSideNavContainer, DekraSideNavItem, DekraSideNavSection, DekraSlider, DekraStep, DekraStepper, DekraSublabel, DekraTab, DekraTabContent, DekraTable, DekraTabs, DekraTag, DekraTagList, DekraTextarea, DekraToast, DekraToasterService, DekraToggle, DekraTooltip, DekraTooltipContent, DekraTree, DekraTreeItem, DekraTriangle, DekraTypeahead, FileValueAccessor, NumberValueAccessor, SelectValueAccessor, TextValueAccessor, ValueAccessor, setDekraClasses };
