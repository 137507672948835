import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { appTranslations } from './app.translations';
import { DekraDesignSystemModule } from '@dekra-ds/angular';
import { CoreModule } from './core/core.module';
import { LandingPageComponent } from './landing-page/landing-page.component';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DekraDesignSystemModule.forRoot(),
    TranslateModule.forRoot(),
    CoreModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.setTranslation('de', {'BUI': appTranslations.de}, true);
    translate.setDefaultLang('de');
    translate.use('de');
  }
}
