export class HttpConfig {
  constructor() {
    this.loading = true;
    this.updateGetAll = true;
    this.forceRefresh = false;
    this.merge = true;
    this.debounceTime = 200;
    this.observe = 'body';
    this.reportProgess = false;
  }
}
