<section class="wide page-spacing" *ngIf="!!globalContent && globalContent.length > 0">
  <h4 [innerHTML]="globalContent"></h4>
</section>
<section class="wide page-spacing">
  <div class="dekra-flex">
    <img class="image" alt="Kostentool" src="assets/Kostentool.jpg" (click)="openCostTool()"/>
    <div class="landing-page-section">
      <h2 class="dekra-margin-left dekra-green--themed">{{'BUI.COST_TOOL.TITLE'|translate}}</h2>
      <div class="dekra-margin-left dekra-text--small" *ngIf="!!costToolContent && costToolContent.length > 0"
           [innerHTML]="costToolContent"></div>
      <dekra-button displayStyle="text" color="green" (click)="openCostTool()">
        <dekra-icon name="chevron-right" slot="icon"></dekra-icon>
        {{'BUI.COST_TOOL.OPEN'|translate}}
      </dekra-button>
    </div>
  </div>
</section>
<section class="wide page-spacing dekra-padding-bottom">
  <div class="dekra-flex">
    <img class="image" alt="Berichtsgenerator" src="assets/Berichtsgenerator.jpg" (click)="openReportGenerator()"/>
    <div class="landing-page-section">
      <h2 class="dekra-margin-left dekra-green--themed">{{'BUI.REPORT_GENERATOR.TITLE'|translate}}</h2>
      <div class="dekra-margin-left dekra-text--small"
           *ngIf="!!reportGeneratorContent && reportGeneratorContent.length > 0"
           [innerHTML]="reportGeneratorContent"></div>
      <dekra-button displayStyle="text" color="green" (click)="openReportGenerator()">
        <dekra-icon name="chevron-right" slot="icon"></dekra-icon>
        {{'BUI.REPORT_GENERATOR.OPEN'|translate}}
      </dekra-button>
    </div>
  </div>
</section>
