var __spreadArray = this && this.__spreadArray || function (e, o, r) {
  if (r || arguments.length === 2) for (var n = 0, i = o.length, g; n < i; n++) {
    if (g || !(n in o)) {
      if (!g) g = Array.prototype.slice.call(o, 0, n);
      g[n] = o[n];
    }
  }
  return e.concat(g || Array.prototype.slice.call(o));
};
var DebounceType;
(function (e) {
  e[e["Choice"] = 0] = "Choice";
  e[e["Keyboard"] = 1] = "Keyboard";
  e[e["Mouseover"] = 2] = "Mouseover";
})(DebounceType || (DebounceType = {}));
function getDebounceConfig(e) {
  var o;
  var r = (o = window.DekraDS) === null || o === void 0 ? void 0 : o.config;
  var n = 0;
  if (!!r && !!r.debounce) {
    if (e === DebounceType.Choice && !!r.debounce.choiceInput) {
      n = r.debounce.choiceInput;
    } else if (e === DebounceType.Keyboard && !!r.debounce.keyboardInput) {
      n = r.debounce.keyboardInput;
    } else if (e === DebounceType.Mouseover && !!r.debounce.mouseover) {
      n = r.debounce.mouseover;
    }
  }
  return n;
}
function getFileSizeConfig() {
  var e;
  var o = (e = window.DekraDS) === null || e === void 0 ? void 0 : e.config;
  var r;
  if (!!o && !!o.fileSize) {
    r = o.fileSize;
  }
  return r;
}
function getLoggerConfig() {
  var e;
  var o = (e = window.DekraDS) === null || e === void 0 ? void 0 : e.config;
  var r = {
    logLevel: LogLevel.Off
  };
  if (!!o && !!o.logging) {
    Object.assign(r, o.logging);
  }
  return r;
}
var LogLevel;
(function (e) {
  e[e["Off"] = 0] = "Off";
  e[e["Error"] = 1] = "Error";
  e[e["Warn"] = 2] = "Warn";
  e[e["Info"] = 3] = "Info";
  e[e["Debug"] = 4] = "Debug";
})(LogLevel || (LogLevel = {}));
var Logger;
(function (e) {
  function o(e) {
    var o = [];
    for (var r = 1; r < arguments.length; r++) {
      o[r - 1] = arguments[r];
    }
    if (getLoggerConfig().logLevel >= LogLevel.Debug) {
      console.debug.apply(console, __spreadArray([e], o, true));
    }
  }
  e.debug = o;
  function r(e) {
    var o = [];
    for (var r = 1; r < arguments.length; r++) {
      o[r - 1] = arguments[r];
    }
    if (getLoggerConfig().logLevel >= LogLevel.Info) {
      console.info.apply(console, __spreadArray([e], o, true));
    }
  }
  e.info = r;
  function n(e) {
    var o = [];
    for (var r = 1; r < arguments.length; r++) {
      o[r - 1] = arguments[r];
    }
    if (getLoggerConfig().logLevel >= LogLevel.Warn) {
      console.warn.apply(console, __spreadArray([e], o, true));
    }
  }
  e.warn = n;
  function i(e) {
    var o = [];
    for (var r = 1; r < arguments.length; r++) {
      o[r - 1] = arguments[r];
    }
    if (getLoggerConfig().logLevel >= LogLevel.Error) {
      console.error.apply(console, __spreadArray([e], o, true));
    }
  }
  e.error = i;
})(Logger || (Logger = {}));
export { DebounceType as D, LogLevel as L, Logger as a, getFileSizeConfig as b, getDebounceConfig as g };