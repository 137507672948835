import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { DekraCostToolClientService } from '@dekra-api/cost-tool-client';
import { CostToolGlobalLandingPage } from '@dekra-api/cost-tool-models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {

  public globalContent?: string;
  public costToolContent?: string;
  public reportGeneratorContent?: string;

  constructor(
    backend: DekraCostToolClientService,
  ) {
    backend.globalLandingPage.getGlobalLandingPagePublic()
      .pipe(takeUntilDestroyed())
      .subscribe((result: CostToolGlobalLandingPage) => {
        this.globalContent = result.globalContent;
        this.costToolContent = result.costToolContent;
        this.reportGeneratorContent = result.reportGeneratorContent;
      });
  }

  public openCostTool() {
    window.open(environment.costToolUrl, '_self');
  }

  public openReportGenerator() {
    window.open(environment.reportGeneratorUrl, '_self');
  }

}
