var RowDisplayType;
(function (e) {
  e[e["success"] = 0] = "success";
  e[e["info"] = 1] = "info";
  e[e["warning"] = 2] = "warning";
  e[e["error"] = 3] = "error";
})(RowDisplayType || (RowDisplayType = {}));
var DekraTableColumnTypes;
(function (e) {
  e["ValueLabelList"] = "valueLabelList";
  e["String"] = "string";
  e["Number"] = "number";
  e["Boolean"] = "boolean";
  e["Date"] = "date";
  e["Initial"] = "initial";
})(DekraTableColumnTypes || (DekraTableColumnTypes = {}));
export { DekraTableColumnTypes as D };