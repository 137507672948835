export function generateUrl(url, params) {
  const keys = params.keys();
  console.debug('[Store] generated url, from ', url, params, keys);
  if (keys.length > 0) {
    let paramString = '';
    keys.forEach(key => {
      if (paramString != '') {
        paramString += '&';
      }
      paramString += key + '=' + encodeURIComponent(params.get(key));
    });
    url = url + '?' + paramString;
  }
  console.debug('[Store] generated url, is ', url);
  return url;
}
