export const appTranslations = {
  de: {
    COST_TOOL: {
      TITLE: 'Kostentool',
      OPEN: 'Kostentool öffnen',
    },
    REPORT_GENERATOR: {
      TITLE: 'Berichtsgenerator',
      OPEN: 'Berichtsgenerator öffnen',
    },
    HEADER: {
      APPLICATION_NAME: 'Bau und Immobilien',
    },
    FOOTER: {
      IMPRINT: 'Impressum',
      TERMS: 'AGB',
      PRIVACY: 'Datenschutz',
    },
  },
};
