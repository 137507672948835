export { D as DekraDatepickerDisplayType, b as DekraDatepickerMonth, a as DekraDatepickerWeekday, c as DekraPickerType } from "./datepicker.enum-764f8bd9.js";
export { D as DekraTableColumnTypes } from "./dekra-table-config-1e534991.js";
import { D as DekraToastVerticalAlignment, a as DekraToastHorizontalAlignment, b as DekraToastType } from "./dekra-toast-alignment-b6d3fa5b.js";
export { a as DekraToastHorizontalAlignment, b as DekraToastType, D as DekraToastVerticalAlignment } from "./dekra-toast-alignment-b6d3fa5b.js";
export { L as LogLevel, a as Logger } from "./logger-50a9e416.js";
export { r as raf } from "./raf-c279f78b.js";
export { f as defaultTranslationsEnglish, e as defaultTranslationsGerman, c as getComponentTranslations, g as getCurrentLocale, d as getTranslation, b as getTranslations, l as localeStore, s as setDefaultLocale, a as setTranslations, u as useLocale } from "./translate.utils-f08f8b4e.js";
export { f as formatDate, a as getCurrentDateFormat, d as getCurrentTimeFormat, g as getDateFormat, c as getTimeFormat, s as setDateFormat, b as setTimeFormat, v as validateDate } from "./date.utils-6befbf39.js";
var DekraDialogConfig = function () {
  function t() {}
  return t;
}();
var DekraToasterServiceCore = function () {
  function t() {
    this.activeToasts = [];
    this.config = {
      verticalAlignment: DekraToastVerticalAlignment.BOTTOM,
      horizontalAlignment: DekraToastHorizontalAlignment.LEFT,
      duration: 8e3
    };
  }
  t.prototype.initToasterService = function () {
    var t;
    this.containerDiv = document.createElement("div");
    this.containerDiv.className = "dekra-toast__stack";
    Object.assign(this.containerDiv.style, (t = {
      position: "fixed"
    }, t[this.config.horizontalAlignment] = 0, t[this.config.verticalAlignment] = 0, t.margin = "var(--d-spacing-themed)", t.display = "inline-flex", t["z-index"] = "9999", t["flex-direction"] = this.config.verticalAlignment === DekraToastVerticalAlignment.BOTTOM ? "column" : "column-reverse", t));
    document.body.appendChild(this.containerDiv);
  };
  t.prototype.setConfig = function (t) {
    Object.assign(this.config, t);
    this.initToasterService();
  };
  t.prototype.createInfo = function (t, e) {
    this.addToast(DekraToastType.INFO, t, e);
  };
  t.prototype.createSuccess = function (t, e) {
    this.addToast(DekraToastType.SUCCESS, t, e);
  };
  t.prototype.createWarning = function (t, e) {
    this.addToast(DekraToastType.WARNING, t, e);
  };
  t.prototype.createError = function (t, e) {
    this.addToast(DekraToastType.ERROR, t, e);
  };
  t.prototype.addToast = function (t, e, a) {
    var s = this;
    if (a === void 0) {
      a = this.config.duration;
    }
    if (!this.containerDiv) {
      this.initToasterService();
    }
    var r = this.activeToasts.length > 0 ? this.activeToasts[this.activeToasts.length - 1].toastId + 1 : 1;
    var i = document.createElement("dekra-toast");
    i.setConfig(t, e, this.config.verticalAlignment, this.config.horizontalAlignment);
    this.containerDiv.appendChild(i);
    this.activeToasts.push({
      toastId: r,
      toastRef: i,
      type: t,
      message: e,
      duration: a
    });
    if (a > 0) {
      setTimeout(function () {
        s.removeToast(r);
      }, a);
    }
  };
  t.prototype.removeToast = function (t) {
    var e = this.activeToasts.findIndex(function (e) {
      return e.toastId === t;
    });
    var a = this.activeToasts[e].toastRef;
    this.containerDiv.removeChild(a);
    this.activeToasts.splice(e, 1);
    if (this.activeToasts.length === 0) {
      if (!!this.containerDiv) document.body.removeChild(this.containerDiv);
      delete this.containerDiv;
    }
  };
  return t;
}();
var DekraToasterServiceSingleton = new DekraToasterServiceCore();
export { DekraDialogConfig, DekraToasterServiceCore, DekraToasterServiceSingleton };