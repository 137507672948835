import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import * as i1 from '@angular/common/http';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { EntityStore, PutStore, HttpConfig, generateUrl } from '@dekra-psk/client-utils';
import { tap } from 'rxjs/operators';
class CostToolCheckClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  checkBackend(onlyHealth, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    params = onlyHealth !== undefined ? params.append('only-health', String(onlyHealth)) : params;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/check`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('check', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolCheckClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolCheckClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolCheckClientService,
    factory: CostToolCheckClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolCheckClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolIncidentsClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  postIncident(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/incidents`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('incidents', url, 'post');
      }
    }));
  }
  static ɵfac = function CostToolIncidentsClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolIncidentsClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolIncidentsClientService,
    factory: CostToolIncidentsClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolIncidentsClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolClientsClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getClients(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/clients`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('clients', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolClientsClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolClientsClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolClientsClientService,
    factory: CostToolClientsClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolClientsClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolContactsClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getContactsPublic(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/contacts`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('contacts', url, call, config, 'get', params);
  }
  getContacts(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/contacts`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('contacts', url, call, config, 'get', params);
  }
  postContact(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/contacts`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('contacts', url, 'post');
      }
    }));
  }
  getContactById(contactId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/contacts/${contactId}`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('contacts', url, call, config, 'get', params);
  }
  putContact(contactId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/contacts/${contactId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('contacts', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('contacts', url, 'put');
      }
    }));
  }
  deleteContact(contactId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/contacts/${contactId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('contacts', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolContactsClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolContactsClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolContactsClientService,
    factory: CostToolContactsClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolContactsClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolFaqsClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getFaqsPublic(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/faqs`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('faqs', url, call, config, 'get', params);
  }
  getFaqs(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/faqs`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('faqs', url, call, config, 'get', params);
  }
  postFaq(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/faqs`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('faqs', url, 'post');
      }
    }));
  }
  getFaqById(faqId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/faqs/${faqId}`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('faqs', url, call, config, 'get', params);
  }
  putFaq(faqId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/faqs/${faqId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('faqs', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('faqs', url, 'put');
      }
    }));
  }
  deleteFaq(faqId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/faqs/${faqId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('faqs', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolFaqsClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolFaqsClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolFaqsClientService,
    factory: CostToolFaqsClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolFaqsClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolLandingPageClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getLandingPagePublic(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/landing-page`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('landing-page', url, call, config, 'get', params);
  }
  getLandingPage(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/landing-page`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('landing-page', url, call, config, 'get', params);
  }
  putLandingPage(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/landing-page`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('landing-page', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('landing-page', url, 'put');
      }
    }));
  }
  static ɵfac = function CostToolLandingPageClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolLandingPageClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolLandingPageClientService,
    factory: CostToolLandingPageClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolLandingPageClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolGlobalLandingPageClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getGlobalLandingPagePublic(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/global-landing-page`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('global-landing-page', url, call, config, 'get', params);
  }
  getGlobalLandingPage(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/global-landing-page`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('global-landing-page', url, call, config, 'get', params);
  }
  putGlobalLandingPage(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/global-landing-page`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('global-landing-page', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('global-landing-page', url, 'put');
      }
    }));
  }
  static ɵfac = function CostToolGlobalLandingPageClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolGlobalLandingPageClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolGlobalLandingPageClientService,
    factory: CostToolGlobalLandingPageClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolGlobalLandingPageClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolApplicationMessageClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getApplicationMessagePublic(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/application-message`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('application-message', url, call, config, 'get', params);
  }
  getApplicationMessage(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/application-message`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('application-message', url, call, config, 'get', params);
  }
  putApplicationMessage(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/application-message`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('application-message', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('application-message', url, 'put');
      }
    }));
  }
  static ɵfac = function CostToolApplicationMessageClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolApplicationMessageClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolApplicationMessageClientService,
    factory: CostToolApplicationMessageClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolApplicationMessageClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolUserGuideClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getUserGuidePublic(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/user-guide`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('user-guide', url, call, config, 'get', params);
  }
  getUserGuide(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/user-guide`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('user-guide', url, call, config, 'get', params);
  }
  putUserGuide(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/user-guide`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('user-guide', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('user-guide', url, 'put');
      }
    }));
  }
  static ɵfac = function CostToolUserGuideClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolUserGuideClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolUserGuideClientService,
    factory: CostToolUserGuideClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolUserGuideClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolFileClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getUserGuideFilePublic(userGuideId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/user-guide/${userGuideId}/file`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('file', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolFileClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolFileClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolFileClientService,
    factory: CostToolFileClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolFileClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolInsurancesClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getInsurancesPublic(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/insurances`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('insurances', url, call, config, 'get', params);
  }
  getInsurances(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/insurances`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('insurances', url, call, config, 'get', params);
  }
  postInsurance(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/insurances`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('insurances', url, 'post');
      }
    }));
  }
  getInsuranceById(insuranceId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/insurances/${insuranceId}`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('insurances', url, call, config, 'get', params);
  }
  putInsurance(insuranceId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/insurances/${insuranceId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('insurances', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('insurances', url, 'put');
      }
    }));
  }
  deleteInsurance(insuranceId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/insurances/${insuranceId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('insurances', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolInsurancesClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolInsurancesClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolInsurancesClientService,
    factory: CostToolInsurancesClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolInsurancesClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolArchivesClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getAllArchivedCalculations(type, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    params = type !== undefined ? params.append('type', String(type)) : params;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/archives`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('archives', url, call, config, 'get', params);
  }
  getArchivedCalculationById(archiveId, expanded, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    params = expanded !== undefined ? params.append('expanded', String(expanded)) : params;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/archives/${archiveId}`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('archives', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolArchivesClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolArchivesClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolArchivesClientService,
    factory: CostToolArchivesClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolArchivesClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolDuplicateClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  duplicateArchivedCalculation(archiveId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/archives/${archiveId}/duplicate`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('duplicate', url, 'post');
      }
    }));
  }
  duplicateCalculationPosition(calculationId, layerOneId, layerTwoId, positionId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/positions/${positionId}/duplicate`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('duplicate', url, 'post');
      }
    }));
  }
  duplicateCalculation(calculationId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/duplicate`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('duplicate', url, 'post');
      }
    }));
  }
  static ɵfac = function CostToolDuplicateClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolDuplicateClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolDuplicateClientService,
    factory: CostToolDuplicateClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolDuplicateClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolCalculationsClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getAllCalculations(type, state, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    params = type !== undefined ? params.append('type', String(type)) : params;
    params = state !== undefined ? params.append('state', String(state)) : params;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('calculations', url, call, config, 'get', params);
  }
  postCalculation(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('calculations', url, 'post');
      }
    }));
  }
  getCalculationById(calculationId, expanded, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    params = expanded !== undefined ? params.append('expanded', String(expanded)) : params;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('calculations', url, call, config, 'get', params);
  }
  putCalculation(calculationId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('calculations', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('calculations', url, 'put');
      }
    }));
  }
  deleteCalculation(calculationId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('calculations', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolCalculationsClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolCalculationsClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolCalculationsClientService,
    factory: CostToolCalculationsClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolCalculationsClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolSearchClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  searchAllCalculations(type, query, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    params = type !== undefined ? params.append('type', String(type)) : params;
    params = query !== undefined ? params.append('query', String(query)) : params;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/search`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('search', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolSearchClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolSearchClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolSearchClientService,
    factory: CostToolSearchClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolSearchClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolLayerOneClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getAllCalculationLayerOnes(calculationId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('layer-one', url, call, config, 'get', params);
  }
  postCalculationLayerOne(calculationId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('layer-one', url, 'post');
      }
    }));
  }
  deleteAllCalculationLayerOnes(calculationId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('layer-one', url, 'delete');
      }
    }));
  }
  deleteLayerOne(calculationId, layerOneId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('layer-one', url, 'delete');
      }
    }));
  }
  getAllLayerOne(calculationTypeId, subTypeId, damageTypeId, expanded, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    params = damageTypeId !== undefined ? params.append('damageTypeId', String(damageTypeId)) : params;
    params = expanded !== undefined ? params.append('expanded', String(expanded)) : params;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculation-types/${calculationTypeId}/sub-types/${subTypeId}/layer-one`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('layer-one', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolLayerOneClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolLayerOneClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolLayerOneClientService,
    factory: CostToolLayerOneClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolLayerOneClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolLayerTwoClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  postCalculationLayerTwo(calculationId, layerOneId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('layer-two', url, 'post');
      }
    }));
  }
  deleteLayerTwo(calculationId, layerOneId, layerTwoId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('layer-two', url, 'delete');
      }
    }));
  }
  getAllLayerTwo(calculationTypeId, subTypeId, layerOneId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculation-types/${calculationTypeId}/sub-types/${subTypeId}/layer-one/${layerOneId}/layer-two`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('layer-two', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolLayerTwoClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolLayerTwoClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolLayerTwoClientService,
    factory: CostToolLayerTwoClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolLayerTwoClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolPositionsClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  postCalculationPosition(calculationId, layerOneId, layerTwoId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/positions`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('positions', url, 'post');
      }
    }));
  }
  putCalculationPosition(calculationId, layerOneId, layerTwoId, positionId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/positions/${positionId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('positions', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('positions', url, 'put');
      }
    }));
  }
  deleteCalculationPosition(calculationId, layerOneId, layerTwoId, positionId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/positions/${positionId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('positions', url, 'delete');
      }
    }));
  }
  postTemplatePosition(templateId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/templates/${templateId}/positions`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('positions', url, 'post');
      }
    }));
  }
  putTemplatePosition(templateId, positionId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/templates/${templateId}/positions/${positionId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('positions', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('positions', url, 'put');
      }
    }));
  }
  deleteTemplatePosition(templateId, positionId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/templates/${templateId}/positions/${positionId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('positions', url, 'delete');
      }
    }));
  }
  getSuggestionPositions(suggestionId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}/positions`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('positions', url, call, config, 'get', params);
  }
  postSuggestionPosition(suggestionId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}/positions`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('positions', url, 'post');
      }
    }));
  }
  getSuggestionPositionById(suggestionId, positionId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}/positions/${positionId}`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('positions', url, call, config, 'get', params);
  }
  putSuggestionPosition(suggestionId, positionId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}/positions/${positionId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('positions', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('positions', url, 'put');
      }
    }));
  }
  deleteSuggestionPosition(suggestionId, positionId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}/positions/${positionId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('positions', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolPositionsClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolPositionsClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolPositionsClientService,
    factory: CostToolPositionsClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolPositionsClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolAmountsClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  postCalculationPositionAmount(calculationId, layerOneId, layerTwoId, positionId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/positions/${positionId}/amounts`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('amounts', url, 'post');
      }
    }));
  }
  putCalculationPositionAmount(calculationId, layerOneId, layerTwoId, positionId, amountId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/positions/${positionId}/amounts/${amountId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('amounts', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('amounts', url, 'put');
      }
    }));
  }
  deleteCalculationPositionAmount(calculationId, layerOneId, layerTwoId, positionId, amountId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/positions/${positionId}/amounts/${amountId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('amounts', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolAmountsClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolAmountsClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolAmountsClientService,
    factory: CostToolAmountsClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolAmountsClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolPricesClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  postCalculationPositionPrice(calculationId, layerOneId, layerTwoId, positionId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/positions/${positionId}/prices`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('prices', url, 'post');
      }
    }));
  }
  putCalculationPositionPrice(calculationId, layerOneId, layerTwoId, positionId, priceId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/positions/${positionId}/prices/${priceId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('prices', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('prices', url, 'put');
      }
    }));
  }
  deleteCalculationPositionPrice(calculationId, layerOneId, layerTwoId, positionId, priceId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/positions/${positionId}/prices/${priceId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('prices', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolPricesClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolPricesClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolPricesClientService,
    factory: CostToolPricesClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolPricesClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolMoveClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  moveCalculationPosition(calculationId, layerOneId, layerTwoId, positionId, direction, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    params = direction !== undefined ? params.append('direction', String(direction)) : params;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/positions/${positionId}/move`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('move', url, 'post');
      }
    }));
  }
  moveItems(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/move`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('move', url, 'post');
      }
    }));
  }
  static ɵfac = function CostToolMoveClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolMoveClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolMoveClientService,
    factory: CostToolMoveClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolMoveClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolPasteClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  pasteCalculationPositions(calculationId, layerOneId, layerTwoId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/paste`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('paste', url, 'post');
      }
    }));
  }
  static ɵfac = function CostToolPasteClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolPasteClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolPasteClientService,
    factory: CostToolPasteClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolPasteClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolSuggestionsClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getLayerTwoSuggestions(calculationId, layerOneId, layerTwoId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/suggestions`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('suggestions', url, call, config, 'get', params);
  }
  insertLayerTwoSuggestions(calculationId, layerOneId, layerTwoId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/suggestions`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('suggestions', url, 'post');
      }
    }));
  }
  getSuggestions(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('suggestions', url, call, config, 'get', params);
  }
  postSuggestion(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('suggestions', url, 'post');
      }
    }));
  }
  getSuggestionById(suggestionId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('suggestions', url, call, config, 'get', params);
  }
  putSuggestion(suggestionId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('suggestions', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('suggestions', url, 'put');
      }
    }));
  }
  deleteSuggestion(suggestionId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('suggestions', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolSuggestionsClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolSuggestionsClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolSuggestionsClientService,
    factory: CostToolSuggestionsClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolSuggestionsClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolArchiveClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  archiveCalculation(calculationId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/archive`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('archive', url, 'post');
      }
    }));
  }
  static ɵfac = function CostToolArchiveClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolArchiveClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolArchiveClientService,
    factory: CostToolArchiveClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolArchiveClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolCommentClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getAllCalculationComments(calculationId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/comment`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('comment', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolCommentClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolCommentClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolCommentClientService,
    factory: CostToolCommentClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolCommentClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolLockClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  lockCalculation(calculationId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/lock`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('lock', url, 'post');
      }
    }));
  }
  releaseCalculation(calculationId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/lock`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('lock', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolLockClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolLockClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolLockClientService,
    factory: CostToolLockClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolLockClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolPdfClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  generatePdf(calculationId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/pdf`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('pdf', url, 'post');
      }
    }));
  }
  static ɵfac = function CostToolPdfClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolPdfClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolPdfClientService,
    factory: CostToolPdfClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolPdfClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolStateClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  changeCalculationState(calculationId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/state`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('state', url, 'post');
      }
    }));
  }
  changeSuggestionPositionsState(suggestionId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}/positions/state`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('state', url, 'post');
      }
    }));
  }
  changeSuggestionPositionState(suggestionId, positionId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}/positions/${positionId}/state`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('state', url, 'post');
      }
    }));
  }
  static ɵfac = function CostToolStateClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolStateClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolStateClientService,
    factory: CostToolStateClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolStateClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolShareClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getAllCalculationShares(calculationId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/share`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('share', url, call, config, 'get', params);
  }
  postCalculationShare(calculationId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/share`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('share', url, 'post');
      }
    }));
  }
  putCalculationShare(calculationId, shareId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/share/${shareId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('share', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('share', url, 'put');
      }
    }));
  }
  deleteCalculationShare(calculationId, shareId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/share/${shareId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('share', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolShareClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolShareClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolShareClientService,
    factory: CostToolShareClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolShareClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolTemplateClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  insertTemplate(calculationId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculations/${calculationId}/template`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('template', url, 'post');
      }
    }));
  }
  static ɵfac = function CostToolTemplateClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolTemplateClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolTemplateClientService,
    factory: CostToolTemplateClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolTemplateClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolTemplatesClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getTemplates(expanded, subType, damageType, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    params = expanded !== undefined ? params.append('expanded', String(expanded)) : params;
    params = subType !== undefined ? params.append('subType', String(subType)) : params;
    params = damageType !== undefined ? params.append('damageType', String(damageType)) : params;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/templates`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('templates', url, call, config, 'get', params);
  }
  postTemplate(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/templates`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('templates', url, 'post');
      }
    }));
  }
  getTemplateById(templateId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/templates/${templateId}`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('templates', url, call, config, 'get', params);
  }
  putTemplate(templateId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/templates/${templateId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('templates', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('templates', url, 'put');
      }
    }));
  }
  deleteTemplate(templateId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/templates/${templateId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('templates', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolTemplatesClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolTemplatesClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolTemplatesClientService,
    factory: CostToolTemplatesClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolTemplatesClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolCalculationTypesClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getAllCalculationTypes(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculation-types`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('calculation-types', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolCalculationTypesClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolCalculationTypesClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolCalculationTypesClientService,
    factory: CostToolCalculationTypesClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolCalculationTypesClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolSubTypesClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getAllCalculationSubTypes(calculationTypeId, expanded, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    params = expanded !== undefined ? params.append('expanded', String(expanded)) : params;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculation-types/${calculationTypeId}/sub-types`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('sub-types', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolSubTypesClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolSubTypesClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolSubTypesClientService,
    factory: CostToolSubTypesClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolSubTypesClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolDamageTypesClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getAllDamageTypes(calculationTypeId, subTypeId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculation-types/${calculationTypeId}/sub-types/${subTypeId}/damage-types`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('damage-types', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolDamageTypesClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolDamageTypesClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolDamageTypesClientService,
    factory: CostToolDamageTypesClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolDamageTypesClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolLayerThreeClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getAllLayerThree(calculationTypeId, subTypeId, layerOneId, layerTwoId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/calculation-types/${calculationTypeId}/sub-types/${subTypeId}/layer-one/${layerOneId}/layer-two/${layerTwoId}/layer-three`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('layer-three', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolLayerThreeClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolLayerThreeClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolLayerThreeClientService,
    factory: CostToolLayerThreeClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolLayerThreeClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolUsersClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getUsers(role, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    params = role !== undefined ? params.append('role', String(role)) : params;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/users`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('users', url, call, config, 'get', params);
  }
  getUserById(userId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/users/${userId}`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('users', url, call, config, 'get', params);
  }
  putUser(userId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/users/${userId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('users', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('users', url, 'put');
      }
    }));
  }
  getUsersPublic(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/users`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('users', url, call, config, 'get', params);
  }
  getUserByIdPublic(userId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/users/${userId}`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('users', url, call, config, 'get', params);
  }
  putUserPublic(userId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/users/${userId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('users', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('users', url, 'put');
      }
    }));
  }
  static ɵfac = function CostToolUsersClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolUsersClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolUsersClientService,
    factory: CostToolUsersClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolUsersClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolQaClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  assignQAUser(userId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/users/${userId}/qa`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('qa', url, 'post');
      }
    }));
  }
  removeQAUser(userId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/users/${userId}/qa`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('qa', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolQaClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolQaClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolQaClientService,
    factory: CostToolQaClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolQaClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolQaUsersClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getQAUsers(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/qa-users`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('qa-users', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolQaUsersClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolQaUsersClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolQaUsersClientService,
    factory: CostToolQaUsersClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolQaUsersClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolBranchesClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getBranches(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/branches`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('branches', url, call, config, 'get', params);
  }
  postBranch(body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/branches`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('branches', url, 'post');
      }
    }));
  }
  getBranchById(branchId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/branches/${branchId}`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('branches', url, call, config, 'get', params);
  }
  putBranch(branchId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/branches/${branchId}`;
    const call = this.httpClient.put(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    if (config.merge) {
      return this.putStore.register(generateUrl(url, params), call).pipe(tap(() => {
        if (config.updateGetAll) {
          this.entityStore.refresh('branches', url, 'put');
        }
      }));
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('branches', url, 'put');
      }
    }));
  }
  deleteBranch(branchId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/branches/${branchId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('branches', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolBranchesClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolBranchesClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolBranchesClientService,
    factory: CostToolBranchesClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolBranchesClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolLayerTwosClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getSuggestionLayerTwos(suggestionId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}/layer-twos`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('layer-twos', url, call, config, 'get', params);
  }
  postSuggestionLayerTwo(suggestionId, body, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}/layer-twos`;
    const call = this.httpClient.post(url, body, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('layer-twos', url, 'post');
      }
    }));
  }
  deleteSuggestionLayerTwo(suggestionId, layerTwoId, config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/master-data/suggestions/${suggestionId}/layer-twos/${layerTwoId}`;
    const call = this.httpClient.delete(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return call.pipe(tap(() => {
      if (config.updateGetAll) {
        this.entityStore.refresh('layer-twos', url, 'delete');
      }
    }));
  }
  static ɵfac = function CostToolLayerTwosClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolLayerTwosClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolLayerTwosClientService,
    factory: CostToolLayerTwosClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolLayerTwosClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class CostToolUserInfoClientService {
  httpClient;
  baseUrl;
  entityStore = new EntityStore();
  putStore = new PutStore();
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  getUserInfo(config) {
    config = Object.assign(new HttpConfig(), config);
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const url = `${this.baseUrl}/user-info`;
    const call = this.httpClient.get(url, {
      headers,
      params,
      observe: config.observe,
      reportProgress: config.reportProgress,
      responseType: config.responseType
    });
    if (config.disableAllUtils) {
      return call;
    }
    return this.entityStore.get('user-info', url, call, config, 'get', params);
  }
  static ɵfac = function CostToolUserInfoClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CostToolUserInfoClientService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: CostToolUserInfoClientService,
    factory: CostToolUserInfoClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CostToolUserInfoClientService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class DekraCostToolClientService {
  check;
  incidents;
  clients;
  contacts;
  faqs;
  landingPage;
  globalLandingPage;
  applicationMessage;
  userGuide;
  file;
  insurances;
  archives;
  duplicate;
  calculations;
  search;
  layerOne;
  layerTwo;
  positions;
  amounts;
  prices;
  move;
  paste;
  suggestions;
  archive;
  comment;
  lock;
  pdf;
  state;
  share;
  template;
  templates;
  calculationTypes;
  subTypes;
  damageTypes;
  layerThree;
  users;
  qa;
  qaUsers;
  branches;
  layerTwos;
  userInfo;
  dekraClientServices;
  constructor(check, incidents, clients, contacts, faqs, landingPage, globalLandingPage, applicationMessage, userGuide, file, insurances, archives, duplicate, calculations, search, layerOne, layerTwo, positions, amounts, prices, move, paste, suggestions, archive, comment, lock, pdf, state, share, template, templates, calculationTypes, subTypes, damageTypes, layerThree, users, qa, qaUsers, branches, layerTwos, userInfo) {
    this.check = check;
    this.incidents = incidents;
    this.clients = clients;
    this.contacts = contacts;
    this.faqs = faqs;
    this.landingPage = landingPage;
    this.globalLandingPage = globalLandingPage;
    this.applicationMessage = applicationMessage;
    this.userGuide = userGuide;
    this.file = file;
    this.insurances = insurances;
    this.archives = archives;
    this.duplicate = duplicate;
    this.calculations = calculations;
    this.search = search;
    this.layerOne = layerOne;
    this.layerTwo = layerTwo;
    this.positions = positions;
    this.amounts = amounts;
    this.prices = prices;
    this.move = move;
    this.paste = paste;
    this.suggestions = suggestions;
    this.archive = archive;
    this.comment = comment;
    this.lock = lock;
    this.pdf = pdf;
    this.state = state;
    this.share = share;
    this.template = template;
    this.templates = templates;
    this.calculationTypes = calculationTypes;
    this.subTypes = subTypes;
    this.damageTypes = damageTypes;
    this.layerThree = layerThree;
    this.users = users;
    this.qa = qa;
    this.qaUsers = qaUsers;
    this.branches = branches;
    this.layerTwos = layerTwos;
    this.userInfo = userInfo;
    this.dekraClientServices = [this.check, this.incidents, this.clients, this.contacts, this.faqs, this.landingPage, this.globalLandingPage, this.applicationMessage, this.userGuide, this.file, this.insurances, this.archives, this.duplicate, this.calculations, this.search, this.layerOne, this.layerTwo, this.positions, this.amounts, this.prices, this.move, this.paste, this.suggestions, this.archive, this.comment, this.lock, this.pdf, this.state, this.share, this.template, this.templates, this.calculationTypes, this.subTypes, this.damageTypes, this.layerThree, this.users, this.qa, this.qaUsers, this.branches, this.layerTwos, this.userInfo];
  }
  setBaseUrl(url) {
    this.dekraClientServices.forEach(service => {
      service.setBaseUrl(url);
    });
  }
  static ɵfac = function DekraCostToolClientService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DekraCostToolClientService)(i0.ɵɵinject(CostToolCheckClientService), i0.ɵɵinject(CostToolIncidentsClientService), i0.ɵɵinject(CostToolClientsClientService), i0.ɵɵinject(CostToolContactsClientService), i0.ɵɵinject(CostToolFaqsClientService), i0.ɵɵinject(CostToolLandingPageClientService), i0.ɵɵinject(CostToolGlobalLandingPageClientService), i0.ɵɵinject(CostToolApplicationMessageClientService), i0.ɵɵinject(CostToolUserGuideClientService), i0.ɵɵinject(CostToolFileClientService), i0.ɵɵinject(CostToolInsurancesClientService), i0.ɵɵinject(CostToolArchivesClientService), i0.ɵɵinject(CostToolDuplicateClientService), i0.ɵɵinject(CostToolCalculationsClientService), i0.ɵɵinject(CostToolSearchClientService), i0.ɵɵinject(CostToolLayerOneClientService), i0.ɵɵinject(CostToolLayerTwoClientService), i0.ɵɵinject(CostToolPositionsClientService), i0.ɵɵinject(CostToolAmountsClientService), i0.ɵɵinject(CostToolPricesClientService), i0.ɵɵinject(CostToolMoveClientService), i0.ɵɵinject(CostToolPasteClientService), i0.ɵɵinject(CostToolSuggestionsClientService), i0.ɵɵinject(CostToolArchiveClientService), i0.ɵɵinject(CostToolCommentClientService), i0.ɵɵinject(CostToolLockClientService), i0.ɵɵinject(CostToolPdfClientService), i0.ɵɵinject(CostToolStateClientService), i0.ɵɵinject(CostToolShareClientService), i0.ɵɵinject(CostToolTemplateClientService), i0.ɵɵinject(CostToolTemplatesClientService), i0.ɵɵinject(CostToolCalculationTypesClientService), i0.ɵɵinject(CostToolSubTypesClientService), i0.ɵɵinject(CostToolDamageTypesClientService), i0.ɵɵinject(CostToolLayerThreeClientService), i0.ɵɵinject(CostToolUsersClientService), i0.ɵɵinject(CostToolQaClientService), i0.ɵɵinject(CostToolQaUsersClientService), i0.ɵɵinject(CostToolBranchesClientService), i0.ɵɵinject(CostToolLayerTwosClientService), i0.ɵɵinject(CostToolUserInfoClientService));
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: DekraCostToolClientService,
    factory: DekraCostToolClientService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraCostToolClientService, [{
    type: Injectable
  }], () => [{
    type: CostToolCheckClientService
  }, {
    type: CostToolIncidentsClientService
  }, {
    type: CostToolClientsClientService
  }, {
    type: CostToolContactsClientService
  }, {
    type: CostToolFaqsClientService
  }, {
    type: CostToolLandingPageClientService
  }, {
    type: CostToolGlobalLandingPageClientService
  }, {
    type: CostToolApplicationMessageClientService
  }, {
    type: CostToolUserGuideClientService
  }, {
    type: CostToolFileClientService
  }, {
    type: CostToolInsurancesClientService
  }, {
    type: CostToolArchivesClientService
  }, {
    type: CostToolDuplicateClientService
  }, {
    type: CostToolCalculationsClientService
  }, {
    type: CostToolSearchClientService
  }, {
    type: CostToolLayerOneClientService
  }, {
    type: CostToolLayerTwoClientService
  }, {
    type: CostToolPositionsClientService
  }, {
    type: CostToolAmountsClientService
  }, {
    type: CostToolPricesClientService
  }, {
    type: CostToolMoveClientService
  }, {
    type: CostToolPasteClientService
  }, {
    type: CostToolSuggestionsClientService
  }, {
    type: CostToolArchiveClientService
  }, {
    type: CostToolCommentClientService
  }, {
    type: CostToolLockClientService
  }, {
    type: CostToolPdfClientService
  }, {
    type: CostToolStateClientService
  }, {
    type: CostToolShareClientService
  }, {
    type: CostToolTemplateClientService
  }, {
    type: CostToolTemplatesClientService
  }, {
    type: CostToolCalculationTypesClientService
  }, {
    type: CostToolSubTypesClientService
  }, {
    type: CostToolDamageTypesClientService
  }, {
    type: CostToolLayerThreeClientService
  }, {
    type: CostToolUsersClientService
  }, {
    type: CostToolQaClientService
  }, {
    type: CostToolQaUsersClientService
  }, {
    type: CostToolBranchesClientService
  }, {
    type: CostToolLayerTwosClientService
  }, {
    type: CostToolUserInfoClientService
  }], null);
})();

/**
 * DO NOT EDIT THIS FILE - IT WILL BE OVERWRITTEN BY EVERY GENERATION
 */
class DekraCostToolClientModule {
  static ɵfac = function DekraCostToolClientModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DekraCostToolClientModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: DekraCostToolClientModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    providers: [CostToolCheckClientService, CostToolIncidentsClientService, CostToolClientsClientService, CostToolContactsClientService, CostToolFaqsClientService, CostToolLandingPageClientService, CostToolGlobalLandingPageClientService, CostToolApplicationMessageClientService, CostToolUserGuideClientService, CostToolFileClientService, CostToolInsurancesClientService, CostToolArchivesClientService, CostToolDuplicateClientService, CostToolCalculationsClientService, CostToolSearchClientService, CostToolLayerOneClientService, CostToolLayerTwoClientService, CostToolPositionsClientService, CostToolAmountsClientService, CostToolPricesClientService, CostToolMoveClientService, CostToolPasteClientService, CostToolSuggestionsClientService, CostToolArchiveClientService, CostToolCommentClientService, CostToolLockClientService, CostToolPdfClientService, CostToolStateClientService, CostToolShareClientService, CostToolTemplateClientService, CostToolTemplatesClientService, CostToolCalculationTypesClientService, CostToolSubTypesClientService, CostToolDamageTypesClientService, CostToolLayerThreeClientService, CostToolUsersClientService, CostToolQaClientService, CostToolQaUsersClientService, CostToolBranchesClientService, CostToolLayerTwosClientService, CostToolUserInfoClientService, DekraCostToolClientService]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DekraCostToolClientModule, [{
    type: NgModule,
    args: [{
      providers: [CostToolCheckClientService, CostToolIncidentsClientService, CostToolClientsClientService, CostToolContactsClientService, CostToolFaqsClientService, CostToolLandingPageClientService, CostToolGlobalLandingPageClientService, CostToolApplicationMessageClientService, CostToolUserGuideClientService, CostToolFileClientService, CostToolInsurancesClientService, CostToolArchivesClientService, CostToolDuplicateClientService, CostToolCalculationsClientService, CostToolSearchClientService, CostToolLayerOneClientService, CostToolLayerTwoClientService, CostToolPositionsClientService, CostToolAmountsClientService, CostToolPricesClientService, CostToolMoveClientService, CostToolPasteClientService, CostToolSuggestionsClientService, CostToolArchiveClientService, CostToolCommentClientService, CostToolLockClientService, CostToolPdfClientService, CostToolStateClientService, CostToolShareClientService, CostToolTemplateClientService, CostToolTemplatesClientService, CostToolCalculationTypesClientService, CostToolSubTypesClientService, CostToolDamageTypesClientService, CostToolLayerThreeClientService, CostToolUsersClientService, CostToolQaClientService, CostToolQaUsersClientService, CostToolBranchesClientService, CostToolLayerTwosClientService, CostToolUserInfoClientService, DekraCostToolClientService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DekraCostToolClientModule, DekraCostToolClientService };
