var DekraPickerType;
(function (e) {
  e[e["DATE"] = 0] = "DATE";
  e[e["TIME"] = 1] = "TIME";
  e[e["DATETIME"] = 2] = "DATETIME";
})(DekraPickerType || (DekraPickerType = {}));
var DekraDatepickerDisplayType;
(function (e) {
  e[e["DAY"] = 0] = "DAY";
  e[e["MONTH"] = 1] = "MONTH";
  e[e["YEAR"] = 2] = "YEAR";
  e[e["DECADE"] = 3] = "DECADE";
  e[e["CENTURY"] = 4] = "CENTURY";
})(DekraDatepickerDisplayType || (DekraDatepickerDisplayType = {}));
var DekraDatepickerWeekday;
(function (e) {
  e[e["SUNDAY"] = 0] = "SUNDAY";
  e[e["MONDAY"] = 1] = "MONDAY";
  e[e["TUESDAY"] = 2] = "TUESDAY";
  e[e["WEDNESDAY"] = 3] = "WEDNESDAY";
  e[e["THURSDAY"] = 4] = "THURSDAY";
  e[e["FRIDAY"] = 5] = "FRIDAY";
  e[e["SATURDAY"] = 6] = "SATURDAY";
})(DekraDatepickerWeekday || (DekraDatepickerWeekday = {}));
var DekraDatepickerMonth;
(function (e) {
  e[e["JANUARY"] = 0] = "JANUARY";
  e[e["FEBRUARY"] = 1] = "FEBRUARY";
  e[e["MARCH"] = 2] = "MARCH";
  e[e["APRIL"] = 3] = "APRIL";
  e[e["MAY"] = 4] = "MAY";
  e[e["JUNE"] = 5] = "JUNE";
  e[e["JULY"] = 6] = "JULY";
  e[e["AUGUST"] = 7] = "AUGUST";
  e[e["SEPTEMBER"] = 8] = "SEPTEMBER";
  e[e["OCTOBER"] = 9] = "OCTOBER";
  e[e["NOVEMBER"] = 10] = "NOVEMBER";
  e[e["DECEMBER"] = 11] = "DECEMBER";
})(DekraDatepickerMonth || (DekraDatepickerMonth = {}));
export { DekraDatepickerDisplayType as D, DekraDatepickerWeekday as a, DekraDatepickerMonth as b, DekraPickerType as c };