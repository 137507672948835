var DekraToastType;
(function (a) {
  a["INFO"] = "info";
  a["SUCCESS"] = "success";
  a["WARNING"] = "warning";
  a["ERROR"] = "error";
})(DekraToastType || (DekraToastType = {}));
var DekraToastVerticalAlignment;
(function (a) {
  a["TOP"] = "top";
  a["BOTTOM"] = "bottom";
})(DekraToastVerticalAlignment || (DekraToastVerticalAlignment = {}));
var DekraToastHorizontalAlignment;
(function (a) {
  a["LEFT"] = "left";
  a["RIGHT"] = "right";
})(DekraToastHorizontalAlignment || (DekraToastHorizontalAlignment = {}));
export { DekraToastVerticalAlignment as D, DekraToastHorizontalAlignment as a, DekraToastType as b };